<template>
  <Dialog
    class="
      modal-custom modal-medium modal-teacher
      hidden-header hidden-icons
      bg-white
    "
    v-model:visible="teacherInfoModalIsOpen"
    :modal="true"
    contentClass="px-0 mx-0 mb-2 overflow-hidden"
  >
    <div class="d-flex justify-content-end m-0 me-4">
      <i
        class="pi pi-times fs-5 pointer text-primary-1 fw-bold"
        @click="(e) => toggleInfoTeacherModal(false)"
      ></i>
    </div>
    <div class="header-user- w-100 text-center">
      <div class="position-relative text-center user-picture">
        <avatar
          :image="
            teacherModal.data?.photo
              ? teacherModal.data?.photo
              : '/assets/images/avatar.png'
          "
          shape="circle"
        />
        <div
          v-if="teacherModal?.type === 'normal'"
          class="
            user-status
            w-auto
            bg-white
            py-1
            px-2
            shadow-sm
            d-flex
            gap-2
            align-items-center
          "
        >
          <div
            class="status"
            :class="
              status[teacherModal.data.status]
                ? status[teacherModal.data.status].class
                : ''
            "
          ></div>
          <span class="fs-6">{{
            status[teacherModal.data.status]
              ? status[teacherModal.data.status].text
              : ""
          }}</span>
        </div>
      </div>
      <div class="mt-3">
        <h1 class="fs-5">
          {{ teacherModal.data.name }} {{ teacherModal.data.last_name }}
        </h1>
      </div>
      <hr />
    </div>
    <div class="py-2 px-4">
      <div class="fs-6 row mb-4" v-if="teacherModal.data?.type === 'normal'">
        <p class="fw-bold col">Time Zone:</p>
        <p class="col">{{ teacherModal.data?.timezone }}</p>
      </div>
      <div class="teacher-video">
        <iframe
          class="w-100 border-r-10 overflow-hidden"
          height="180px"
          :src="
            teacherModal.data?.video
              ? getId(teacherModal.data?.video)
              : teacherModal.data?.url_video ? getId(teacherModal.data?.url_video) : ''
          "
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
          lazyload
        ></iframe>
      </div>
    </div>

    <template #footer>
      <div
        class="d-flex justify-content-center gap-2 flex-column"
        v-if="teacherModal?.type === 'normal'"
      >
        <button
          :disabled="loadingFavorite"
          class="
            btn
            text-white
            bg-primary-1 bg-hover-primary-1
            bl-shadow
            py-2
            shadow
            w-100
            d-flex
            gap-2
            justify-content-center
          "
          @click="() => goToBook(true,teacherModal.data)"
        >
          <span class="material-icons"> calendar_month </span>
          Book a class
        </button>
        <button
          class="d-flex gap-2 justify-content-center"
          :disabled="loadingFavorite"
          :class="
            teacherModal.found
              ? 'btn text-secondary-1 border-secondary-1 bg-hover-light-secondary-1 w-100'
              : 'btn text-white border-secondary-1 bg-secondary-1 w-100'
          "
          @click="(e) => { 
            teacherModal.found ? toggleDeleteFavorite(teacherModal.data.teacher_id)  :  addRemoveFavorite(teacherModal.data.teacher_id)
            
          }"
        >
          <span class="material-icons">
            {{ teacherModal.found ? "favorite_border" : "favorite" }}
          </span>
          {{
            teacherModal.found
              ? "Remove as favorite"
              : "Set as favorite teacher"
          }}
        </button>
      </div>
    </template>
  </Dialog>
</template>

<script>
import useMyTeachers from "@/modules/students/composables/MyTeachers/useMyTeachers";
import useSharedUI from "@/shared/composables/useSharedUI";
import usePrivateLayout from "@/shared/composables/Layouts/usePrivateLayout";
export default {
  name: "TeacherInfoModal",
  setup() {
    const {
      teacherInfoModalIsOpen,
      toggleDeleteFavorite,
      toggleInfoTeacherModal,
      teacherModal,
      addRemoveFavorite,
      loadingFavorite,
      teachersFavorites,
      getId,
    } = useMyTeachers();
    const { userAuthenticated, goToBook } = useSharedUI();
    const { status } = usePrivateLayout();

    return {
      showModal: true,
      goToBook,
      teachersFavorites,
      getId,
      teacherModal,
      teacherInfoModalIsOpen,
      toggleDeleteFavorite,
      loadingFavorite,
      toggleInfoTeacherModal,
      userAuthenticated,
      addRemoveFavorite,
      status,
    };
  },
};
</script>

<style lang="scss" scoped>
.header-user- .p-avatar {
  height: 7.5rem;
  width: 7.5rem;
}
</style>
