<template>
  <Dialog header="Header" v-model:visible="isOpenDeleteEvent" :modal="true" class="hidden-icons modal-custom"
    :draggable="false" :closable="false" :showHeader="false" contentClass="px-4 pb-1 pt-4">
    <div class="header d-flex gap-3">
      <div class="header-logo">
        <img src="/assets/images/logo-icon.png" alt="Bright Lingua" />
      </div>
      <div class="header-texts">
        <h1 class="title text-secondary-1 fs-6 mb-0">CAREFUL!</h1>
        <h2 class="question text-primary-0 fw-bolder fs-3">
          Are you sure to delete Job Schedule?
        </h2>
      </div>
    </div>
    <template #footer>
      <div class="d-flex flex-row justify-content-end align-items-end gap-2">
        <button class="
          btn
          text-secondary-1
          border-secondary-1
          bg-hover-light-secondary-1
          p-2
          px-5
          w-50
          w-sm-auto
        " @click="deleteEventData">
          Yes
        </button>
        <button class="
          btn
          text-white
          bg-secondary-1 bg-hover-secondary-1
          p-2
          px-5
          bl-shadow
          w-50
          w-sm-auto" 
          @click="() => toggleModalDeleteEvent()">
          No
        </button>

      </div>
    </template>
  </Dialog>
</template>

<script>
import useScheduledTeacher from '../../../../composables/Members/Teachers/useScheduledTeacher';

export default {
  name: "DeleteEventModal",
  setup() {
    const { isOpenDeleteEvent , toggleModalDeleteEvent , deleteEventData } = useScheduledTeacher();

    return {
        isOpenDeleteEvent,
        deleteEventData,
        toggleModalDeleteEvent
    };
  },
};
</script>
