<template>
  <aside
    class="menu-mobile overflow-auto scroll bg-primary-1 d-flex d-md-none position-fixed w-0 flex-column justify-content-between  py-4"
    :class="(menuHamburger.isOpen ? 'menu-mobile-open ' : 'menu-mobile-close ') + (esDispositivoiOS() ? 'iphone-menu' : '')" style="z-index:100">
    <div class="menu-navigator menu-navigator-mobile">
      <div class="
          empty-menu
          text-white text-center
          h-100
          d-flex
          align-items-center
          justify-content-center
          px-4
        " v-if="isStudent && studentInfo.subscription_canceled && studentBilling">
        <h2 class="fs-1">Subscribe to unlock features</h2>
      </div>
      <template v-else-if="sidebarItems.length > 0">
        <div class="item" v-for="(item, index) in sidebarItems" :key="index" :id="'item_' + index + '_mobile'">
          <router-link :to="{
            name:
              item.name === 'admin-members' || item.name === 'admin-analytics'
                ? item.childrens[0].name
                : item.name,
          }" class="
              item-url
              text-white text-decoration-none
              d-flex
              gap-2
              px-4
              py-3
            " :class="
              this.$route.name.includes(item.name)
                ? 'router-link-exact-active'
                : ''
            " @click="toggleMenuHamburger">
            <span class="material-icons">{{ item.icon }}</span>
            <span>{{ item.label }}</span>
          </router-link>
          <template v-if="item?.childrens && this.$route.name.includes(item.name)">
            <div class="item" v-for="(itemChildren, index2) in item.childrens" :key="index2">
              <router-link :to="{ name: itemChildren.name }" class="
                  text-decoration-none
                  d-flex
                  gap-2
                  px-4
                  py-3
                  ps-5
                  sub-item-url
                " :class="[
                  this.$route.name === itemChildren.name
                    ? 'bg-primary-4 text-primary-1'
                    : 'bg-primary-6 text-primary-1',
                ]" @click="toggleMenuHamburger">
                <span>{{ itemChildren.label }}</span>
              </router-link>
            </div>
          </template>
        </div>
      </template>
      <div class="
          empty-menu
          text-white text-center
          h-100
          d-flex
          align-items-center
          justify-content-center
        " v-else>
        <h2 class="fs-3">Complete your profile to unlock features</h2>
      </div>
    </div>
    <notification-class-component v-if="!isAdmin && notificationClass.isOpen" />

    <div class="menu-logout">
      <template v-if="
        (isStudent && userAuthenticated.completed_info && isSubscribeStudent) || !studentBilling
      ">
        <div class="item" v-for="(item, i) in uiSecondaryItemsData" :key="i">
          <router-link :id="i === 1 ? 'item_5_mobile' : ''" :to="{ name: item.name }" class="
              item-url
              text-white text-decoration-none
              d-flex
              gap-2
              px-4
              py-3
            " @click="toggleMenuHamburger">
            <span class="material-icons">{{ item.icon }}</span>
            <span>{{ item.label }}</span>
          </router-link>
        </div>
      </template>
      <div class="item">
        <div class="
            item-url
            text-white text-decoration-none
            d-flex
            gap-2
            px-4
            py-3
            pointer
            logout_menu_item
          " @click="toggleLogoutModal(true)">
          <span class="material-icons"> logout </span>
          <span>Log out</span>
        </div>
      </div>
    </div>
    <tour :data="data" v-if="tourIsVisible" @update="update($event)"> </tour>
  </aside>
</template>

<script>
import useSharedUI from "@/shared/composables/useSharedUI";
import NotificationClassComponent from "@/shared/components/Notifications/NotificationClassComponent";
import uiSecondaryItemsData from "@/modules/students/data/UI/uiSecondaryItemsData";
import useDashboardStudent from "@/modules/students/composables/Dashboard/useDashboard";
import useInformation from "@/modules/students/composables/useStudentInformation";
import data from "@/modules/students/tours/tourDashboardProfile";
import useMenuBar from "../../composables/useMenuBar";

export default {
  data: () => {
    return {
      data,
    };
  },
  computed: {
    tourIsVisible() {
      return this.data.index >= 0 && this.data.index < this.data.steps.length;
    },
    isTour() {
      return this.$store.getters["students/startTour"];
    },
  },
  methods: {
    esDispositivoiOS() {
      var userAgent = window.navigator.userAgent;

      return /iP(hone|od|ad)/.test(userAgent);
    },
    deleteValue() {
      localStorage.removeItem(data.localStorageKey);
      this.update(0);
    },
    changeArrowPosition(position) {
      const arrow = document.querySelectorAll('.tour-arrow')[1]
      arrow.style = 'display:block !important';
      if (position === 5) {
        arrow.style.top = '157px'
        arrow.style.left = '40%'
        arrow.style.transform = 'rotate(270deg)'

      } else {
        arrow.style.top = '-28px'
        arrow.style.left = '40%'
        arrow.style.transform = 'rotate(90deg)'

      }
    },
    update(index) {
      this.data.index = index;
      if (index < 0) {
        this.data.index = 0;
        this.finish();
        this.$store.commit("students/startTour", { isOpen: false });
      }
      this.changeArrowPosition(index);
    },
    start() {
      if (window.innerWidth < 768) {
        const positions = []
        for (let i = 0; i < 6; i++) {
          const element = document.getElementById('item_' + i + "_mobile");
          const position = element.getBoundingClientRect()
          positions.push(position.top - 20)
        }
        this.data.steps = [
          {
            left: "50px",
            right: "50px",
            top: positions[0] + "px",
            bottom: "",
            direction: "right",
            content:
              "TRACK YOUR PROGRESS: In your dashboard you will be able to track your progress and have the shortcuts at hand to optimize your learning.",
            next: "Next",
            scrollTop: 1,
            targetElementId: "item_0_mobile",
          },
          {
            left: "50px",
            right: "50px",
            top: positions[1] + "px",
            bottom: "",
            direction: "right",
            content:
              "EXPLORE OUR CONTENT: Take a look at your materials and review your Bright Path.",
            next: "Next",
            scrollTop: 1,
            targetElementId: "item_1_mobile",
          },
          {
            left: "50px",
            right: "50px",
            top: positions[2] + "px",
            bottom: "",
            direction: "right",
            content:
              "SCHEDULE YOUR 1-1 CLASS: Book a class and check out your scheduled classes.",
            next: "Next",
            scrollTop: 1,
            targetElementId: "item_2_mobile",
          },
          {
            left: "50px",
            right: "50px",
            top: positions[3] + "px",
            bottom: "",
            direction: "right",
            content:
              "LEARN WITH NATIVE TEACHERS: Meet our teachers, mark them as your favorite, and rate their performance.",
            next: "Next",
            scrollTop: 1,
            targetElementId: "item_3_mobile",
          },
          {
            left: "50px",
            right: "50px",
            top: positions[4] + "px",
            bottom: "",
            direction: "right",
            content: "BILLING: Take a look at your billing history.",
            next: "Next",
            scrollTop: 1,
            targetElementId: "item_4_mobile",
          },
          {
            left: "50px",
            right: "50px",
            top: (positions[5] - 225) + "px",
            bottom: "",
            direction: "right",
            content:
              "ANY OTHER QUESTIONS: If you have additional questions, you can enter them here.",
            next: "Finish",
            scrollTop: 1,
            targetElementId: "item_5_mobile",
          },
        ];

        const firstArrowInterval = setInterval(() => {
          const arrow = document.querySelectorAll('.tour-arrow')[1]
          if (arrow) {
            this.changeArrowPosition();
            clearInterval(firstArrowInterval)
          }
        }, 100);

      }

    },
    finish() {
      this.data.steps = [];
      this.$router.push('/students/student-my-classes')
      this.$store.commit("shared/menuHamburger", { isOpen: false });
    },
  },
  created() {
    this.$store.watch(
      (state, getters) => getters["students/startTour"].isOpen,
      (newValue, oldValue) => {
        if (newValue) {
          this.start();
        } else {
          this.finish();
        }
      }
    );
  },
  components: { NotificationClassComponent },
  setup() {
    const {
      toggleLogoutModal,
      notificationClass,
      sidebarItems,
      isAdmin,
      getCurrentMenuItems,
      currentSidebarItems,
      isStudent,
      isSubscribeStudent,
    } = useSharedUI();
    const { studentInfo, userAuthenticated, studentBilling } = useInformation();
    const { dashboard } = useDashboardStudent();
    const { menuHamburger, toggleMenuHamburger } = useMenuBar()
    getCurrentMenuItems();
    return {
      menuHamburger,
      toggleMenuHamburger,
      userAuthenticated,
      studentInfo,
      uiSecondaryItemsData,
      sidebarItems,
      dashboard,
      toggleLogoutModal,
      currentSidebarItems,
      notificationClass,
      isAdmin,
      isStudent,
      isSubscribeStudent,
      studentBilling
    };
  },
};
</script>

<style>
.menu-navigator-mobile {
  min-height: 50%;
}
</style>