<template>
  <Dialog header="Header" class="modal-custom modal-medium modal-student-feedback bg-white"
    v-model:visible="feedbackModal.isOpen" :modal="true" contentClass="pb-1 mt-1">
    <template #header>
      <h1 class="fs-2 fw-bold text-center text-secondary-1 w-100 mb-0">
        Teacher's notes
      </h1>
    </template>

    <div class="header-user- header-user-big w-100 text-center">
      <avatar :image="
        feedbackModal.data.student_photo
          ? feedbackModal.data.student_photo
          : '/assets/images/avatar.png'
      " shape="circle" />
    </div>
    <div class="text-center my-2">
      <h4 class="mb-0">{{ feedbackModal.data.name }}</h4>
      <p>
        {{
            formatDate(
              feedbackModal.data.lesson_start !== undefined
                ? feedbackModal.data.lesson_start
                : "1990-04-04 05:00:00",
              "complete"
            )
        }}
      </p>
      <!-- <p>Thursday , 09 December 2021 at 05:30pm</p> -->
    </div>

    <div class="my-3">
      <template v-if="!feedbackModal.data.send_feedback">
        <template v-for="(topic, key, index) in questions" :key="index">
          <p class="text-secondary-1">{{ key.toUpperCase() }}</p>
          <div v-for="(question, index2) in topic" :key="index2">
            <template v-if="question.type === 'stars'">
              <div class="d-flex align-items-center justify-content-between mb-3">
                <p class="fw-bold mb-1">{{ question.question }}</p>
                <rating v-model="answers.answers[key][index2].stars" :cancel="false"
                  :disabled="feedbackModal.mood !== 'new'" />
              </div>

            </template>
            <template v-else-if="question.type === 'content'">
              <div class="field-blue w-100 mb-3">
                <p class="fw-bold">Describe the class*</p>
                <Textarea :autoResize="true" :disabled="feedbackModal.mood !== 'new'" rows="5"
                  :value="answers.answers[key][index2].comment"
                  v-model="answers.answers[key][index2].comment"
                  :modelValue="answers.answers[key][index2].comment" class="w-100 border-r-10 mt-2"
                  placeholder="Please give as much information as possible about the class (class topic, skills, student's behavior, etc)."
                  />
              </div>
            </template>
            <template v-else>
              <div class="mb-3">
                <p class="fw-bold">{{ question.question }}</p>
                <div class="d-flex gap-3 mt-1">
                  <div class="d-flex gap-2 align-items-center">
                    <radio-button name="assistance" value="Yes" v-model="answers.answers[key][index2].accept"
                      :disabled="feedbackModal.mood !== 'new'" />
                    <label>Yes</label>
                  </div>
                  <div class="d-flex gap-2 align-items-center">
                    <radio-button name="assistance" value="No" v-model="answers.answers[key][index2].accept"
                      :disabled="feedbackModal.mood !== 'new'" />
                    <label>No</label>
                  </div>
                </div>
              </div>

            </template>
          </div>
        </template>
      </template>
      <template v-else>
        <template v-for="(topic, key, index) in getInfoFeedback()" :key="index">
          <p class="text-secondary-1">{{ key.toUpperCase() }}</p>
          <div v-for="(question, index2) in topic" :key="index2">
            <template v-if="question.type === 'stars'">
              <div class="d-flex align-items-center justify-content-between mb-3">
                <p class="fw-bold mb-1">{{ question.question }}</p>
                <rating v-model="question.stars" :cancel="false" :disabled="feedbackModal.mood !== 'new'" />
              </div>
            </template>
            <template v-else-if="question.type === 'content'">
              <div class="field-blue w-100 mb-3">
                <p class="fw-bold">Describe the class*</p>
                <Textarea :autoResize="true" :disabled="feedbackModal.mood !== 'new'" rows="5" v-model="question.answer"
                  class="w-100 border-r-10 mt-2" placeholder="Give any comment you would like to share. " />
              </div>
            </template>
            <template v-else>
              <div class="mb-3">
                <p class="fw-bold">{{ question.question }}</p>
                <div class="d-flex gap-3 mt-1">
                  <div class="d-flex gap-2 align-items-center">
                    <radio-button name="assistance" value="Yes" v-model="question.answer"
                      modelValue="question.answer"
                      :disabled="feedbackModal.mood !== 'new'" />
                    <label>Yes</label>
                  </div>
                  <div class="d-flex gap-2 align-items-center">
                    <radio-button name="assistance" value="No" v-model="question.answer"
                      modelValue="question.answer"
                      :disabled="feedbackModal.mood !== 'new'" />
                    <label>No</label>
                  </div>
                </div>
              </div>

            </template>
          </div>
        </template>
      </template>
    </div>

    <template #footer v-if="feedbackModal.mood === 'new'">
      <div class="d-flex justify-content-center gap-2 flex-column">
        <button :disabled="isLoadingSend" class="
            btn
            text-white
            bg-secondary-1 bg-hover-secondary-1
            py-2
            shadow
            w-100" 
            @click="savefeedback">
          Submit feedback
        </button>
        <button :disabled="isLoadingSend" class="
            btn
            text-secondary-1
            border-secondary-1
            bg-hover-light-secondary-1
            w-100
          " @click="() => {
            Object.entries(feedbackOrNoteConfirm.data).length === 0 ? toggleModal(false) : toogleModalConfirmFeedback()
          }">
          {{ Object.entries(feedbackOrNoteConfirm.data).length === 0 ? 'Cancel' : 'I already submitted it' }}
        </button>
      </div>
    </template>
  </Dialog>
</template>

<script>
import useFeedback from "@/modules/teachers/composables/Student/useFeedback";
import formatDate from "@/shared/utils/changeTexts/formatDate";
import useSharedUI from "../../../../shared/composables/useSharedUI";
import { useStore } from "vuex";
import { computed, onUpdated, ref } from "vue";
export default {
  name: "FeedbackModal",
  setup() {
    const store = useStore();
    const { toogleModalConfirmFeedback, feedbackOrNoteConfirm } = useSharedUI()
    const { sendFeedback, answers } = useFeedback()

    const savefeedback = () => {
      sendFeedback();
      setTimeout(() => {
        store.commit('shared/feedbackOrNoteConfirm', {
          data: {}, isOpenConfirm: false, isLoading: false
        }); 
      }, 5000);
    }

    return {
      formatDate,
      ...useFeedback(),
      toogleModalConfirmFeedback,
      feedbackOrNoteConfirm,
      store,
      savefeedback,
      answers
    };
  },
};
</script>
