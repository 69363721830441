<template>
  <Dialog
    header="Header"
    class="modal-custom modal-big-2 btns-max hidden-icons header-blue bg-white"
    v-model:visible="adminModal.isOpen"
    :modal="true"
    :draggable="false"
    contentClass="px-0 mx-0 mb-2 overflow-hidden"
  >
    <template #header>
      <h1 class="fs-5 text-start text-white w-100 mb-0">
        {{ adminModal.status === "add" ? "New admin" : "" }}
        {{ adminModal.status === "edit" ? "Edit admin profile" : "" }}
        {{ adminModal.status === "show-info" ? "Admin" : "" }}
      </h1>
    </template>
    <div class="body px-4 pt-3">
      <h1 class="text-primary-0 fw-bold fs-3">Basic info</h1>

      <div class="px-3 py-3 scroll overflow-auto content-fields">
        <template v-if="adminModal.loadingInfo && adminModal.status === 'edit'">
          <skeleton
            borderRadius="10px"
            height="2.3rem"
            class="mb-3 skeleton-light"
            v-for="skeleton in [1, 2, 3, 4]"
            :key="skeleton"
          />
          <div
            class="d-flex mb-3 gap-3"
            v-for="skeleton in [1, 2, 3]"
            :key="skeleton"
          >
            <skeleton
              borderRadius="10px"
              height="2.3rem"
              class="skeleton-light"
              v-for="skeleton in [1, 2]"
              :key="skeleton"
            />
          </div>
          <div class="d-flex gap-3">
            <div class="w-100">
              <skeleton
                shape="circle"
                width="10rem"
                height="10rem"
                class="skeleton-light mx-auto mb-3"
              />
              <skeleton
                borderRadius="10px"
                width="70%"
                height="1.4rem"
                class="skeleton-light mx-auto mb-2"
              />
              <skeleton
                borderRadius="10px"
                width="85%"
                height="1.4rem"
                class="skeleton-light mx-auto"
              />
            </div>
            <skeleton
              borderRadius="10px"
              height="2.3rem"
              class="skeleton-light"
            />
          </div>
        </template>
        <template v-else>
          <div class="field-blue col-1 w-100">
            <label for="names" class="h6 m-0">Name(s)*</label>
            <input-text
              type="text"
              name="name"
              v-model="fields.name"
              :disabled="adminModal.status === 'show-info'"
            />
          </div>
          <div class="field-blue col-1 w-100">
            <label for="last_name" class="h6 m-0">Last name(s)*</label>
            <input-text
              type="text"
              name="last_name"
              v-model="fields.last_name"
              :disabled="adminModal.status === 'show-info'"
            />
          </div>
          <div class="field-blue col-1 w-100">
            <label for="address" class="h6 m-0">Address</label>
            <input-text
              type="text"
              name="address"
              v-model="fields.address"
              :disabled="adminModal.status === 'show-info'"
            />
          </div>
          <div class="field-blue col-1 w-100">
            <label for="email" class="h6 m-0">Email*</label>
            <input-text
              type="email"
              name="email"
              v-model="fields.email"
              :disabled="adminModal.status === 'show-info'"
            />
          </div>
          <div class="grid-2 w-100">
            <div class="field-blue col-1 w-100">
              <label class="h6 m-0">Gender</label>
              <dropdown
                :options="listings.genders"
                optionLabel="value"
                optionValue="value"
                placeholder="Select gender"
                name="value"
                class="w-100"
                v-model="fields.gender"
                :filter="true"
                :disabled="adminModal.status === 'show-info'"
              />
            </div>
            <div class="field-blue col-1 col-1- w-100">
              <label class="h6 m-0">Phone</label>
              <div class="d-flex align-items-center gap-2">
                <dropdown
                  :options="listings.countrycodes.data"
                  :loading="listings.countrycodes.isLoading"
                  optionLabel="dial_code"
                  optionValue="id"
                  placeholder="Select code"
                  name="value"
                  class="w-100"
                  v-model="fields.phone_code_id"
                  :filter="true"
                  @filter="selectCode"
                  :disabled="adminModal.status === 'show-info'"
                />
                <!-- <input-number
                  name="phone"
                  mode="decimal"
                  class="w-100"
                  :useGrouping="false"
                  v-model="fields.phone_code_id"
                  :disabled="adminModal.status === 'show-info'"
                /> -->
                <input-number
                  name="phone"
                  mode="decimal"
                  class="w-100"
                  :useGrouping="false"
                  v-model="fields.cellphone"
                  :disabled="adminModal.status === 'show-info'"
                />
              </div>
            </div>
          </div>
          <div class="grid-2 w-100">
            <div class="field-blue col-1 w-100">
              <label class="h6 m-0">Birthday</label>
              <Calendar
                id="icon"
                :showIcon="false"
                class="w-100"
                name="birth"
                :maxDate="new Date(store.getters['shared/currentTimeTimezone'])"
                v-model="fields.birth"
                dateFormat="mm/dd/yy"
                :disabled="adminModal.status === 'show-info'"
              />
            </div>
            <div class="field-blue col-1 col-1- w-100">
              <label class="h6 m-0">Hire date</label>
              <Calendar
                id="icon"
                :showIcon="false"
                class="w-100"
                name="birth"
                :maxDate="new Date(store.getters['shared/currentTimeTimezone'])"
                v-model="fields.hire_date"
                dateFormat="mm/dd/yy"
                :disabled="adminModal.status === 'show-info'"
              />
            </div>
          </div>
          <div class="grid-2 w-100">
            <div class="field-blue col-1 w-100">
              <label class="h6 m-0">Role</label>
              <dropdown
                :options="listings.roles"
                optionLabel="value"
                optionValue="id"
                placeholder="Select role"
                name="value"
                class="w-100"
                v-model="fields.role_id"
                :filter="true"
                :disabled="adminModal.status === 'show-info'"
              />
            </div>
            <div class="field-blue col-1 col-1- w-100">
              <label class="h6 m-0">Time zone*</label>
              <dropdown
                :options="listings.timesZones.data"
                :loading="listings.timesZones.isLoading"
                optionLabel="timezone_change"
                optionValue="timezone_change"
                placeholder="Select a time zone"
                name="timezone"
                v-model="fields.timezone_change"
                :filter="true"
              />
            </div>
          </div>

          <div class="grid-2 w-100">
            <div class="field-blue w-100">
              <label class="h6 m-0">Insert profile picture</label>
              <div
                class="header-user- header-user-big mx-auto w-100 text-center"
              >
                <div
                  @click="changeAvatar"
                  v-if="!changingPhoto"
                  class="position-relative text-center user-picture"
                  :class="[adminModal.status !== 'show-info' ? 'pointer' : '']"
                >
                  <avatar :image="urlAvatar" shape="circle" class="" />
                  <div
                    class="
                      edit-avatar
                      text-secondary-2
                      align-items-center
                      justify-content-center
                    "
                    v-if="adminModal.status !== 'show-info'"
                    :class="[!urlAvatar ? 'edit-show' : '']"
                  >
                    <i class="pi pi-camera"></i>
                    <input
                      type="file"
                      class="d-none"
                      ref="inputFile"
                      @change="onSelectedImage"
                    />
                  </div>
                </div>
                <skeleton
                  v-else
                  borderRadius="50%"
                  height="8rem"
                  width="8rem"
                  class="skeleton-dark m-auto"
                />
                <p
                  class="mt-2 fs-6 text-primary-1 px-5 lh-sm"
                  v-if="adminModal.status !== 'show-info'"
                >
                  The image must be 110x110 pixels and in .jpg .png format.
                </p>
              </div>
            </div>
            <div class="field-blue w-100">
              <label class="h6 m-0 mb-2">Insert video presentation</label>
              <input-text
                type="url"
                name="video"
                v-model="fields.link_video"
                placeholder="Insert YouTube link"
                :disabled="adminModal.status === 'show-info'"
              />
              <iframe
                v-if="isShowIframe"
                class="mt-3"
                height="210"
                width="100%"
                :src="
                  'https://www.youtube.com/embed/' +
                  idIframe +
                  '?autoplay=1&mute=1'
                "
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                frameborder="0"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </template>
      </div>
    </div>
    <template #footer>
      <div class="d-flex gap-2 justify-content-end">
        <button
          class="
            btn
            text-secondary-1
            border-secondary-1
            bg-hover-light-secondary-1
            py-2
            px-4
          "
          :disabled="isLoadingAdminCreation || isLoadingAdminUpdate"
          @click="toggleAdminModal({ isOpen: false, status: '' })"
        >
          {{ adminModal.status === "show-info" ? "Close" : "Cancel" }}
        </button>
        <button
          class="
            btn
            text-white
            bg-secondary-1 bg-hover-secondaty-1
            bl-shadow
            py-2
            shadow
            px-4
          "
          v-if="adminModal.status === 'add'"
          @click="createOrUpdateAdmin('create')"
          :disabled="isLoadingAdminCreation"
        >
          {{ isLoadingAdminCreation ? "Creating..." : "Create" }}
        </button>

        <button
          class="
            btn
            text-white
            bg-secondary-1 bg-hover-secondaty-1
            bl-shadow
            py-2
            shadow
            px-4
          "
          @click="createOrUpdateAdmin('update')"
          :disabled="adminModal.loadingInfo || isLoadingAdminUpdate"
          v-if="adminModal.status === 'edit'"
        >
          {{ isLoadingAdminCreation ? "Saving..." : "Save" }}
        </button>
      </div>
    </template>
  </Dialog>
</template>

<script>
import useAdminAddOrUpdate from "@/modules/admin/composables/Members/Admins/useAdminAddOrUpdate";
import { useStore } from 'vuex'

export default {
  name: "AdminModal",
  setup() {
    const store = useStore()
    return {
      ...useAdminAddOrUpdate(),
      store
    };
  },
};
</script>

<style lang="scss" scoped>
.grid-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.8rem;
}
.col-1 {
  display: grid;
  grid-template-columns: 6.5rem 1fr;
  align-items: center;
  gap: 1.5rem;
  margin-bottom: 1.4rem;
  &- {
    grid-template-columns: 5rem 1fr;
  }
}
.content-fields {
  max-height: 18rem;
}
</style>