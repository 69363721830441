<template>
  <Dialog
    header="Header"
    class="modal-custom modal-big btns-max hidden-icons bg-white"
    v-model:visible="modal.isOpen"
    :modal="true"
    contentClass="px-0 mx-4 mb-2 "
    :draggable="false"
  >
    <template #header>
      <div class="d-flex justify-content-between align-items-center w-100">
        <h1 class="fs-2 fw-bold text-center text-primary-1 mb-0">
          New content
        </h1>
        <div class="d-flex gap-3">
          <button
            @click="resetPayload"
            :disabled="listings.isLoading || isCreatingContent"
            class="
              btn
              text-secondary-1
              border-secondary-1
              bg-hover-light-secondary-1
              py-2
              px-4
            "
          >
            <i class="pi pi-refresh"></i>
          </button>
          <button
            @click="goToPrevisualize"
            :disabled="listings.isLoading || isCreatingContent"
            class="
              btn
              text-secondary-1
              border-secondary-1
              bg-hover-light-secondary-1
              py-2
              px-4
            "
          >
            View
          </button>
        </div>
      </div>
    </template>
    <div class="body px-4">
      <div class="col-2 gap-2 w-100 mb-4">
        <div class="field-blue align-items-center">
          <label for="">Select level</label>
          <!-- {{ JSON.stringify(listings, null, 3) }} -->
          <dropdown
            v-model="payload.level_data"
            :options="listings.data"
            @change="selectLevel"
            :loading="listings.isLoading"
            :disabled="listings.isLoading"
            placeholder="Select level"
            optionLabel="name"
            :optionValue="
              (level) => {
                return {
                  id: level.id,
                  name: level.name,
                  sublevels: level.sublevels,
                };
              }
            "
            class="w-100"
          />
        </div>
        <div class="field-blue gap-2 align-items-center">
          <label for="">Select sub-level</label>
          <dropdown
            v-model="payload.sub_level_id"
            :options="sublevels"
            placeholder="Select sub level"
            :disabled="listings.isLoading"
            :loading="currentSubLevels.isLoading"
            optionLabel="name"
            optionValue="id"
            class="w-100"
          />
        </div>
      </div>
      <div class="field-blue col-1 w-100 mb-4">
        <label for="">Title</label>
        <input-text
          type="text"
          :disabled="listings.isLoading"
          v-model="payload.title"
          class="w-100"
        />
      </div>
      <div class="field-blue col-1 w-100 mb-5">
        <label for="" class="w-100 mb-2">Description</label>
        <Textarea
          v-model="payload.description"
          class="w-100"
          height="90%"
          rows="5"
          :disabled="listings.isLoading"
          :autoResize="false"
          placeholder="Describe the new content"
        />
      </div>
      <hr />
      <div
        class="w-100 rounded d-flex align-items-center justify-content-center"
        style="height: 5rem; background-color: #edf1fa"
        @click="toggle"
      >
        <i class="pi pi-plus" style="font-size: 1.5rem"></i>
      </div>
      <Menu ref="menu" :model="items" :popup="true" />
      <div class="content-body mt-4">
        <div
          class="content-body-item my-3"
          v-for="(value, index) in payload.infoData"
          :key="index"
        >
          <div
            class="d-flex align-items-center gap-3"
            v-if="value.type !== 'text' && value.type"
          >
            <!-- {{ value.id }} -->
            <div
              class="d-flex justify-content-between align-items-center w-100"
            >
              <div
                class="
                  content-body-item-file
                  rounded-3
                  d-flex
                  gap-4
                  w-auto
                  align-items-center
                  p-3
                "
                style="background-color: #edf1fa; width: 65% !important"
              >
                <span>Attach file </span>
                <div
                  @click="() => addFile('file' + value.id)"
                  class="
                    current-level
                    d-flex
                    gap-3
                    align-items-center
                    bg-primary-1
                    rounded-pill
                    py-2
                    px-4
                    text-white
                    w-50
                    text-center
                  "
                >
                  <span class="w-100">{{
                    value.name !== "" && value.name ? value.name.substr(0, 10) : "Search"
                  }}</span>
                  <input
                    class="form-control d-none"
                    :object="value.id"
                    type="file"
                    :id="'file' + value.id"
                    @change="selectFile"
                    accept="application/pdf,audio/*,video/*"
                  />
                </div>
              </div>
              <div class="d-block text-center" style="width: 50px">
                <Button
                  icon="pi pi-arrow-up"
                  class="
                    p-button-primary
                    p-component
                    p-button-icon-only
                    p-button-rounded
                    p-button-text
                    w-100
                  "
                  @click="() => orderUp(value)"
                />
                <Button
                  icon="pi pi-trash"
                  class="
                    p-button-danger
                    p-component
                    p-button-icon-only
                    p-button-rounded
                    p-button-text
                  "
                  @click="() => deleteItem(value.id)"
                />
                <Button
                  icon="pi pi-arrow-down"
                  class="
                    p-button-primary
                    p-component
                    p-button-icon-only
                    p-button-rounded
                    p-button-text
                    w-100
                  "
                  @click="() => orderDown(value)"
                />
              </div>
            </div>
          </div>

          <div
            v-else-if="value.type !== null"
            class="content-body-item-editor d-flex align-items-center gap-3"
          >
            <!-- {{ value.id }} -->
            <div
              class="p
                d-flex
                justify-content-between
                align-items-center
                w-100
                gap-3
              "
            >
              <editor v-model="value.value" editorStyle="height: 150px" @text-change="(e) => changeTextContent(value)">
                <template v-slot:toolbar>
                  <span class="ql-formats">
                    <button class="ql-bold" v-tooltip.bottom="'Bold'"></button>
                    <button
                      class="ql-italic"
                      v-tooltip.bottom="'Italic'"
                    ></button>
                    <button
                      class="ql-underline"
                      v-tooltip.bottom="'Underline'"
                    ></button>
                  </span>
                  <span class="ql-formats">
                    <button
                      class="ql-list"
                      v-tooltip.bottom="'Ordered'"
                      value="ordered"
                    ></button>
                    <button
                      class="ql-list"
                      v-tooltip.bottom="'Bullet'"
                      value="bullet"
                    ></button>
                    <button
                      class="ql-image"
                      v-tooltip.bottom="'Image'"
                      value="image"
                    ></button>
                    <button
                      class="ql-link"
                      v-tooltip.bottom="'Link'"
                      value="link"
                    ></button>
                    <button
                      class="ql-code-block"
                      v-tooltip.bottom="'Code'"
                      value="code-block"
                    ></button>
                  </span>
                </template>
              </editor>
              <div class="d-block text-center">
                <Button
                  icon="pi pi-arrow-up"
                  class="
                    p-button-primary
                    p-component
                    p-button-icon-only
                    p-button-rounded
                    p-button-text
                    w-100
                  "
                  @click="() => orderUp(value)"
                />
                <Button
                  icon="pi pi-trash"
                  class="
                    p-button-danger
                    p-component
                    p-button-icon-only
                    p-button-rounded
                    p-button-text
                    w-100
                  "
                  @click="() => deleteItem(value.id)"
                />

                <Button
                  icon="pi pi-arrow-down"
                  class="
                    p-button-primary
                    p-component
                    p-button-icon-only
                    p-button-rounded
                    p-button-text
                    w-100
                  "
                  @click="() => orderDown(value)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <template #footer>
      <div class="d-flex gap-2 justify-content-end">
        <button
          class="
            btn
            text-secondary-1
            border-secondary-1
            bg-hover-light-secondary-1
            py-2
            px-4
          "
          :disabled="listings.isLoading || isCreatingContent"
          @click="() => toggleModalContent(false, true, true)"
        >
          Cancel
        </button>
        <button
          @click="
            () => {
              if (contentAdd.isEdit) createContent('update');
              else createContent();
            }
          "
          class="
            btn
            text-white
            bg-secondary-1 bg-hover-secondaty-1
            bl-shadow
            py-2
            shadow
            px-4
            d-flex align-items-center gap-3 justify-content-center
          "
          :disabled="listings.isLoading || isCreatingContent"
        >
          <i v-if="isCreatingContent" class="pi pi-spin pi-spinner" style="font-size: 1.5rem"></i>
          {{ contentAdd.isEdit ? ( isCreatingContent ? "Updating" : "Update") : ( isCreatingContent ? "Creating" : "Create") }}
        </button>
      </div>
    </template>
  </Dialog>
</template>

<script>
import useContentAdd from "@/modules/admin/composables/MyLibrary/useContentAdd";

export default {
  name: "ContentAddModal",
  setup() {
    return {
      ...useContentAdd(),
    };
  },
};
</script>

<style lang="scss" scoped>
.col-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  & .field-blue {
    display: grid;
    grid-template-columns: 7rem 1fr;
  }
}
.col-1 {
  display: grid;
  grid-template-columns: 7rem 1fr;
}
.p-editor-container {
  width: 100% !important;
}
</style>
