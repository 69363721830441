<template>
  <Dialog header="Header" class="modal-custom modal-medium modal-student-feedback bg-white"
    v-model:visible="lastTeacherModal.isOpen" :modal="true" contentClass="pb-1 mt-1">
    <template #header>
      <h1 class="fs-2 fw-bold text-center text-secondary-1 w-100 mb-0">
        Teacher's feedback
      </h1>
    </template>
    <div class="header-user- header-user-big w-100 text-center">
      <avatar :image="
        feedback.data?.photo
          ? feedback.data?.photo
          : '/assets/images/avatar.png'
      " shape="circle" />
    </div>
    <div class="text-center mt-2">
      <h4 class="mb-0">
        {{ feedback.data.name }} {{ feedback.data.last_name }}
      </h4>
      <p>Day, {{ formatDate(feedback.data.lesson_start, "large") }}</p>
    </div>

    <skeleton v-if="questions_data.isLoading" borderRadius="10px" height="20rem" class="skeleton-dark p-3 mt-3" />
    <template v-else>
      <template v-if="!feedback.data.send_feedback">
        <div v-for="(topics, key, index) in questions" :key="index" class="field-blue">
          <p class="text-secondary-1">{{ key.toUpperCase() }}</p>
          <template v-for="(question, index2) in topics" :key="index2">
            <div class="d-flex justify-content-between flex-column gap-2 flex-lg-row mb-4">
              <p class="fw-bold">{{ question.question }}</p>
              <div v-if="key.toUpperCase() != 'COMMENTS'">
                <rating v-model="answers.answers[key][index2].stars" :disabled="lastTeacherModal.mood === 'edit'"
                  :cancel="false" :stars="5" />
              </div>
            </div>
            <div class="field-blue w-100" v-if="key.toUpperCase() == 'COMMENTS'">
              <Textarea :autoResize="true" rows="5" :disabled="lastTeacherModal.mood === 'edit'"
                v-model="answers.answers[key][index2].comment" class="w-100 border-r-10 my-2"
                placeholder="Give any comment you would like to share... " />
            </div>
          </template>
        </div>
      </template>
      <template v-else>
        <div v-for="(topics, key, index) in getInfoFeedback()" :key="index" class="field-blue">
          <p class="text-secondary-1">{{ key.toUpperCase() }}</p>
          <template v-for="(feedback, index2) in topics" :key="index2">
            <div class="d-flex justify-content-between gap-2 flex-column md:flex-row mb-4">
              <p class="fw-bold">{{ feedback.question }}</p>
              <rating :disabled="lastTeacherModal.mood === 'edit'" :cancel="false" :stars="5"
                v-model="feedback.stars"
                v-if="key.toUpperCase() != 'COMMENTS'" />
            </div>
            <Textarea
            v-if="key.toUpperCase() == 'COMMENTS'" :autoResize="true" :disabled="lastTeacherModal.mood === 'edit'" :modelValue="feedback.answer"
              rows="5" class="w-100 border-r-10 mt-2" placeholder="Give any comment you would like to share. " />
          </template>
        </div>
        <!-- <p class="text-secondary-1">1 TOPIC’s NAME</p>
        <div
          v-for="(feedback, index) in feedback.data.feedback"
          :key="index"
          class="field-blue"
        >
          <div class="d-flex justify-content-between gap-3">
            <p class="fw-bold">{{ feedback.question }}</p>
            <rating
              :disabled="true"
              :cancel="false"
              :stars="5"
              v-model="feedback.stars"
            />
          </div>
          <Textarea
            :value="feedback.comment"
            :autoResize="true"
            rows="5"
            :disabled="true"
            class="w-100 border-r-10 mt-2"
            placeholder="Write here your comments"
          />
        </div> -->
      </template>
    </template>

    <template #footer v-if="lastTeacherModal.mood === 'new'">
      <div class="d-flex justify-content-center gap-2 flex-column">
        <button class="
            btn
            text-white
            bg-secondary-1 bg-hover-secondary-1
            py-2
            shadow
            w-100
          " @click="() => {
            store.commit('shared/feedbackOrNoteConfirm', { data: {}, isOpenConfirm: false, isLoading: false });
          
            sendFeedback()
          }" :disabled="isLoadingSend">
          Submit feedback
        </button>
        <button class="btn text-secondary-1 text-hover-secondary-1 w-100" @click="() => {
          Object.entries(feedbackOrNoteConfirm.data).length === 0 ? toggleLastTeacherModal(false, {}, 'new') : toogleModalConfirmFeedback()
        }">
          {{ Object.entries(feedbackOrNoteConfirm.data).length === 0 ? 'Cancel' : 'I already submitted it' }}
        </button>
      </div>
    </template>
  </Dialog>
</template>

<script>
import useFeedback from "@/modules/students/composables/MyTeachers/useFeedback";
import useSharedUI from "../../../../shared/composables/useSharedUI";
import formatDate from "@/shared/utils/changeTexts/formatDate";
import { useStore } from "vuex";
export default {
  name: "FeedbackModal",
  setup() {
    const store = useStore()
    const { toogleModalConfirmFeedback, feedbackOrNoteConfirm } = useSharedUI();
    return {
      ...useFeedback(),
      toogleModalConfirmFeedback,
      feedbackOrNoteConfirm,
      formatDate,
      store
    };
  },
};
</script>
