<template>
  <Dialog
    header="Header"
    class="modal-custom modal-big-2 btns-max hidden-icons header-blue bg-white"
    v-model:visible="sendEmailModal.isOpen"
    :modal="true"
    contentClass="px-0 mx-0 mb-2 overflow-hidden pb-4"
  >
    <template #header>
      <h1 class="fs-5 text-start text-white w-100 mb-0">Send email</h1>
    </template>
    <div class="body px-4 pt-4">
      <div class="field-blue d-flex gap-3 align-items-center mb-3">
        <label for="" class="h6 m-0 m-w-120px">Send to:</label>
        <input-text type="text" name="name" :value="payload.emailSelectsText" :disabled="true"/>
      </div>
      <div class="field-blue d-flex align-items-center mb-1 gap-3 mb-3">
        <label for="" class="h6 m-0 m-w-120px">Select template:</label>
        <Dropdown
          v-model="payload.templateName"
          :options="templateEmails.data"
          optionLabel="name"
          optionValue="name"
          placeholder="Select template"
          class="w-100"
          :loading="templateEmails.isLoading"
          @change="selectTemplate"
        >
        </Dropdown>
      </div>
      <div class="field-blue d-flex align-items-center mb-1 gap-3 mb-3">
        <label for="" class="h6 m-0 m-w-120px">Name template</label>
        <input-text type="text" name="name" v-model="payload.templateName"/>
      </div>
      <div class="field-blue">
        <editor v-model="payload.data" editorStyle="height: 100px">
          <template v-slot:toolbar>
            <span class="ql-formats">
              <button class="ql-bold" v-tooltip.bottom="'Bold'"></button>
              <button class="ql-italic" v-tooltip.bottom="'Italic'"></button>
              <button
                class="ql-underline"
                v-tooltip.bottom="'Underline'"
              ></button>
            </span>
            <span class="ql-formats">
              <button
                class="ql-list"
                v-tooltip.bottom="'Ordered'"
                value="ordered"
              ></button>
              <button
                class="ql-list"
                v-tooltip.bottom="'Bullet'"
                value="bullet"
              ></button>
              <button
                class="ql-image"
                v-tooltip.bottom="'Image'"
                value="image"
              ></button>
              <button
                class="ql-link"
                v-tooltip.bottom="'Link'"
                value="link"
              ></button>
              <button
                class="ql-code-block"
                v-tooltip.bottom="'Code'"
                value="code-block"
              ></button>
            </span>
          </template>
        </editor>
      </div>
    </div>
    <template #footer>
      <div class="d-flex justify-content-between w-100">
        <button
          :disabled="isSendingEmails || isSavingTemplate"
          type="button"
          @click="createTemplateEmail"
          class="btn text-secondary-1 text-hover-secondary-1"
        >
          Save changes
        </button>
        <div class="d-flex gap-2 justify-content-end w-100">
          <button
            :disabled="isSendingEmails || isSavingTemplate"
            class="btn text-secondary-1 border-secondary-1 bg-hover-light-secondary-1 py-2 px-4"
            @click="cancelSendEmails"
          >
            Cancel
          </button>
          <button
            :disabled="isSendingEmails || payload.templateName === '' || isSavingTemplate"
            :loading="isSengindEmails"
            class="btn text-white bg-secondary-1 bg-hover-secondaty-1 bl-shadow py-2 shadow px-4"
            @click="sendEmails"
          >
            Send email
          </button>
        </div>
      </div>
    </template>
  </Dialog>
</template>

<script>
import useSendEmail from "@/modules/admin/composables/Members/useSendEmail";
export default {
  name: "SendEmailModal",
  setup() {
    return {
      ...useSendEmail(),
    };
  },
};
</script>

<style lang="scss" scoped></style>
