<template>
    <Dialog header="Header" class="modal-custom modal-big btns-max hidden-icons header-blue bg-white"
        v-model:visible="scheduled.isOpenCreate" :modal="true" :draggable="false" contentClass="px-0 m-3 mb-2 ">
        <template #header>
            <h1 class="bg-primary-1 fs-5 text-start text-white w-100 mb-0">
                Create Job schedule
            </h1>
        </template>
        <div class="body d-flex gap-2">
            <div class="body px-2 pt-3 d-flex align-items-center">
                <span class="fw-bold m-0 w-50">Start date: </span>
                <Calendar :showTime="true" v-model="newEvent.start" :minDate="new Date(store.getters['shared/currentTimeTimezone'])" :step-minute="30" dateFormat="mm/dd/yy" />
            </div>
            <div class="body px-2 pt-3 d-flex align-items-center">
                <span class="fw-bold m-0 w-50">End date: </span>
                <Calendar :showTime="true" v-model="newEvent.end" :step-minute="30" :minDate="new Date(store.getters['shared/currentTimeTimezone'])" dateFormat="mm/dd/y" />
            </div>
        </div>
        <template #footer>
            <div class="d-flex gap-2 justify-content-end">
                <div class="d-flex gap-2 justify-content-end">
                    <button class="
            btn
            text-secondary-1
            border-secondary-1
            bg-hover-light-secondary-1
            py-2
            px-4
          " @click="toggleCreateJobscheduledModal">
                        Cancel
                    </button>
                    <button class="
            btn
            text-white
            bg-secondary-1 bg-hover-secondaty-1
            bl-shadow
            py-2
            shadow
            px-4
            " @click="createManualEvent">
                        Create
                    </button>
                </div>
            </div>
        </template>
    </Dialog>
</template>

<script>
import useScheduledTeacher from '../../../../composables/Members/Teachers/useScheduledTeacher';
import { useStore } from 'vuex'
export default {
    name: "CreateJobScheduledModal",
    setup() {
        const {
            scheduled,
            toggleCreateJobscheduledModal,
            newEvent,
            createManualEvent
        } = useScheduledTeacher()
        const store = useStore()
        return {
            scheduled,
            createManualEvent,
            newEvent,
            toggleCreateJobscheduledModal,
            store
        }
    },
};
</script>
