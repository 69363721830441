<template>
  <Dialog
    header="Header"
    v-model:visible="isShowUpgradeLevelModal.isOpen"
    :modal="true"
    class="modal-custom hidden-icons modal-small"
  >
    <template #header>
      <h1 class="fs-2 fw-bold text-center text-secondary-1 w-100">
        Upgrade level
      </h1>
    </template>
    <form class="content" v-on:submit.prevent="">
      <div class="row- mb-2 field-blue">
        <p class="fw-bold">Current level:</p>

        <input-text
          type="text"
          readonly
          class="input-small rounded-0"
          :value="student.level"
        />
      </div>
      <div class="row- mb-2 field-blue">
        <p class="fw-bold">Change to:</p>
        <dropdown
          :options="levels.data"
          v-model="payload.level_id"
          optionLabel="level_name_short"
          @change="changeLevel"
          optionValue="id"
          placeholder="Level"
          class="dropdown-small p-0 rounded-0"
        />
      </div>
      <div class="mb-1">
        <p class="mb-2 fw-bold">Reason:</p>
        <div v-if="false" class="form-check mb-2">
          <input
            class="form-check-input"
            type="radio"
            name="reason-student"
            value="New student"
            id="reason-student"
          />
          <label class="form-check-label" for="reason-student">
            New student
          </label>
        </div>
        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            name="reason-student"
            value="Passed the upgrade level test."
            id="reason-student"
          />
          <label class="form-check-label" for="reason-student">
            Passed the upgrade level test.
          </label>
        </div>
      </div>
    </form>
    <template #footer>
      <div class="d-flex justify-content-center gap-2">
        <button
          :disabled="isUpgradeStudent"
          @click="() => toggleUpgradeLevelModal(false)"
          class="btn text-secondary-1 border-secondary-1 bg-hover-light-secondary-1"
        >
          Cancel
        </button>
        <button
          @click="updgradeStudent"
          :disabled="isUpgradeStudent"
          class="btn text-white bg-secondary-1 bg-hover-secondary-1 py-2 bl-shadow"
       >
          Save
        </button>
      </div>
    </template>
  </Dialog>
</template>

<script>
import useInformation from "@/modules/admin/composables/Members/Students/useInformation";
import useCurrentLibrary from "@/shared/composables/Library/useCurrentLibrary";
import useStudentProfile from "@/modules/admin/composables/Members/Students/useStudentProfile";
import { useStore } from "vuex";
export default {
  name: "UpgradeLevelModal",
  setup() {
    const store = useStore()
    const {
      isShowUpgradeLevelModal,
      toggleUpgradeLevelModal,
    } = useInformation();

    const { getCurrentLibrary } = useCurrentLibrary();
    const { student , levels , isUpgradeStudent , updgradeStudent , payload , changeLevel } = useStudentProfile();
    
   
    return {
      isUpgradeStudent,
      updgradeStudent,
      changeLevel,
      payload,
      toggleUpgradeLevelModal,
      levels,
      student,
      isShowUpgradeLevelModal,
      getCurrentLibrary,
    };
  },
};
</script>

<style lang="scss" scoped>
.row- {
  display: grid;
  grid-template-columns: 8rem 1fr;
}
button {
  max-width: 150px;
  width: 100% !important;
}
</style>
