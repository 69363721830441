<template>
  <Dialog
    header="Header"
    class="modal-custom modal-medium btns-max hidden-icons header-blue bg-white"
    v-model:visible="exportChartModal.isOpen"
    :modal="true"
    :draggable="false"
    contentClass="px-0 mx-0  overflow-hidden"
  >
    <template #header>
      <h1 class="bg-primary-1 fs-5 text-start text-white w-100 mb-0">
        Export Charts
      </h1>
    </template>
    <div class="body px-4 pt-3 mt-2">
      <p class="fs-6 text-neutro-1 fw-bold mb-2">
        1. Select the date range you want to filter for your chart
      </p>
      <div
        class="
          d-flex
          align-items-center
          gap-2
          justify-content-between
          w-100
          mb-3
        "
      >
        <div class="field-outline-blue d-flex gap-2 align-items-center">
          <p>Start:</p>
          <Calendar id="icon" class="w-100 calendar-small" name="birth" />
        </div>
        <div class="field-outline-blue d-flex gap-2 align-items-center">
          <p>Finish:</p>
          <Calendar id="icon" class="w-100 calendar-small" name="birth" />
        </div>
      </div>
      <p class="fs-6 text-neutro-1 fw-bold mb-2">
        2. Select the charts you want to export
      </p>
      <div
        class="
          form-check
          d-flex
          gap-2
          align-items-center
          justify-content-start
          w-100
          mb-2
        "
        v-for="(chart, index) in optionsCharts"
        :key="index"
      >
        <input
          class="form-check-input"
          type="checkbox"
          value=""
          :id="{ index }"
        />
        <label class="form-check-label" :for="index">
          {{ chart.label }}
        </label>
      </div>
    </div>
    <template #footer>
      <div class="d-flex gap-2 justify-content-end">
        <button
          class="
            btn
            text-secondary-1
            border-secondary-1
            bg-hover-light-secondary-1
            py-2
            px-4
          "
        >
          Cancel
        </button>
        <button
          class="
            btn
            text-white
            bg-secondary-1 bg-hover-secondaty-1
            bl-shadow
            py-2
            shadow
            px-4
          "
        >
          Export
        </button>
      </div>
    </template>
  </Dialog>
</template>

<script>
import useRegisters from "@/modules/admin/composables/Analitycs/Marketing/useRegisters";
import useRewards from "@/modules/admin/composables/Analitycs/Marketing/useRewards";
export default {
  name: "ExportChartsModal",
  setup() {
    const { optionsCharts } = useRegisters();
    const { exportChartModal } = useRewards();
    return { optionsCharts, exportChartModal };
  },
};
</script>

<style>
</style>