<template>
  <main class="layout-private">
    <menu-main-component />
    <div class="body bg-transparent vh-100">
      <menu-bar-component />
      <menu-main-component-mobile />
      <div
        id="body-main"
        class="body-main scroll"
        :style="
          !userAuthenticated.completed_info &&
          currentRoute === 'student-dashboard' && widthScreen > 1201
            ? ''
            : 'overflow:auto'
        "
      >
        <router-view />
      </div>
    </div>
  </main>
  <logout-modal />
  <confirmation-modal />
  <notification-list-modal />

  <template
    v-if="
      userAuthenticated.role === 'teacher' || userAuthenticated.role === 'admin' || userAuthenticated.role === 'coordinator'
    "
  >
    <modal-new-password />
    <user-info-modal />
  </template>

   <template
    v-if="
      userAuthenticated.role === 'teacher' || userAuthenticated.role === 'student'
    "
  >
    <confirm-feedback-submit />
  </template>

  <!-- TEACHER MODALS -->
  <template v-if="userAuthenticated.role === 'teacher'">
    <teacher-student-upgrade-level-modal />
    <teacher-student-feedback-modal />
    <feedback-modal-student />
  </template>

  <!-- STUDENT MODALS -->
  <template v-if="userAuthenticated.role === 'student'">
    <student-teacher-info-modal />
    <student-payment-delete />
    <student-teacher-feedback-modal />
    <terms-conditions-modal />
    <student-payment-suscription-delete />
    <class-is-booked-modal />
    <student-confirm-mini-tour />
    <student-confirm-cancel-class />
    <confirm-delete-favorite />
  </template>

  <!--ADMIN MODALS -->
  <template v-if="userAuthenticated.role === 'admin' || userAuthenticated.role === 'coordinator'">
    <member-admin-modal />
    <admin-calendar-modal />
    <member-upgrade-level-modal />
    <clear-scheduled-date />
    <member-student-lead-add-modal />
    <member-student-reset-email-modal />
    <member-student-send-email />
    <create-job-scheduled-modal />
    <admnin-feedback-modal />
    <delete-event-modal />
    <member-teacher-modal />
    <block-teacher-modal />
    <delete-last-changes />
    <list-teachers />
    <!-- MemberTeacherChangeRoleModal -->
    <member-teacher-change-role-modal :roles="roles" />
    <admin-content-add-modal />

    <analytics-financial-export-charts />
    <analytics-marketing-export-charts />
    <analytics-marketing-approve-rewards />
  </template>
</template>

<script>
import MenuMainComponent from "@/shared/components/Menus/MenuMainComponent";
import MenuMainComponentMobile from "../components/Menus/MenuMainComponentMobile";
import MenuBarComponent from "@/shared/components/Menus/MenuBarComponent";

import LogoutModal from "@/shared/components/Modals/LogoutModal";
import ConfirmationModal from "@/shared/components/Modals/ConfirmationModal";
import UserInfoModal from "@/shared/components/Modals/UserInfoModal";
import NotificationListModal from "@/shared/components/Modals/NotificationListModal";
import ClassIsBookedModal from "@/shared/components/ScheduledClass/ClassIsBookedModal";
import usePrivateLayout from "@/shared/composables/Layouts/usePrivateLayout";
import ConfirmFeedbackSubmit from "@/shared/components/Feedback/ConfirmFeedbackSubmit";
import ConfirmDeleteFavorite from "../../modules/students/components/MyTeachers/ConfirmDeleteFavorite";
import DeleteEventModal from "../../modules/admin/components/Members/Teachers/Scheduled/DeleteEventModal";
import BlockTeacherModal from "../../modules/admin/components/Members/Teachers/BlockTeacherModal";
import DeleteLastChanges from "../../modules/admin/components/Members/Teachers/Scheduled/DeleteLastChanges";
import ListTeachers from "../../modules/admin/components/Members/Teachers/Scheduled/ListTeachers";

import FeedbackModalStudent from "../../modules/teachers/components/Student/FeedbackModalStudent";

import TeacherStudentUpgradeLevelModal from "@/modules/teachers/components/Student/UpgradeLevelModal";
import TeacherStudentFeedbackModal from "@/modules/teachers/components/Student/FeedbackModal";

import StudentTeacherInfoModal from "@/modules/students/components/MyTeachers/TeacherInfoModal";
import TermsConditionsModal from "@/modules/students/components/Rewards/TermsConditionsModal";
// import StudentTeacherFeedbackModal from "@/modules/students/components/MyTeachers/FeedbackModal";
import StudentPaymentDelete from "@/modules/students/components/Billing/PaymentDeleteModal";
import StudentPaymentSuscriptionDelete from "@/modules/students/components/Billing/PaymentSuscriptionDelete";
import StudentTeacherFeedbackModal from "@/modules/students/components/MyTeachers/FeedbackModal";
import StudentConfirmMiniTour from "@/modules/students/components/StudentProfile/ConfirmMiniTour";
import StudentConfirmCancelClass from "@/modules/students/components/MyClasses/ConfirmCancelClass";

import MemberAdminModal from "@/modules/admin/components/Members/Admins/AdminModal";
import MemberStudentLeadAddModal from "@/modules/admin/components/Members/Students/LeadAddModal";
import MemberStudentSendEmail from "@/modules/admin/components/Members/SendEmailModal";
import AdminCalendarModal from "@/modules/admin/components/Calendar/CalendarModal";
import MemberStudentResetEmailModal from "@/modules/admin/components/Members/ResetEmailModal";
import MemberTeacherModal from "@/modules/admin/components/Members/Teachers/TeacherModal";
import MemberTeacherChangeRoleModal from "@/modules/admin/components/Members/Teachers/Profile/ChangeRoleModal";
import MemberUpgradeLevelModal from "@/modules/admin/components/Members/Students/UpgradeLevelModal";
import AdmninFeedbackModal from "@/modules/admin/components/Members/FeedbackModal";

import ClearScheduledDate from "../../modules/admin/components/Members/Teachers/Scheduled/ClearScheduledDate";
import CreateJobScheduledModal from "../../modules/admin/components/Members/Teachers/Scheduled/CreateJobScheduledModal";

import AnalyticsFinancialExportCharts from "@/modules/admin/components/Analytics/Financial/ExportChartsModal";
import AnalyticsMarketingExportCharts from "@/modules/admin/components/Analytics/Marketing/Registers/ExportChartsModal";
import AnalyticsMarketingApproveRewards from "@/modules/admin/components/Analytics/Marketing/Rewards/ApproveRewardsModal";
import AdminContentAddModal from "@/modules/admin/components/MyLibrary/ContentAddModal";
import { computed, onBeforeMount, onUpdated, watchEffect, onMounted } from "vue";
import ModalNewPassword from "@/modules/teachers/components/Modals/ModalNewPassword";
import { useRouter } from "vue-router";
import useInformation from "@/modules/students/composables/useStudentInformation";
import useCalendar from "@/shared/composables/Calendar/useCalendar";
import { useStore } from "vuex";

export default {
  name: "PrivateLayout",
  components: {
    /* globals */
    MenuMainComponent,
    MenuBarComponent,
    MenuMainComponentMobile,
    LogoutModal,
    ConfirmationModal,
    UserInfoModal,
    NotificationListModal,
    ClassIsBookedModal,
    ConfirmFeedbackSubmit,
    /* teacher */
    TeacherStudentUpgradeLevelModal,
    TeacherStudentFeedbackModal,
    ModalNewPassword,
    FeedbackModalStudent,
    DeleteLastChanges,
    // TeacherCalendarModal,

    /* students */
    // StudentTeacherFeedbackModal,
    TermsConditionsModal,
    StudentTeacherInfoModal,
    StudentPaymentDelete,
    StudentTeacherFeedbackModal,
    StudentPaymentSuscriptionDelete,
    StudentConfirmMiniTour,
    StudentConfirmCancelClass,
    ConfirmDeleteFavorite,

    /* admin */
    AdminCalendarModal,
    CreateJobScheduledModal,
    MemberAdminModal,
    MemberUpgradeLevelModal,
    MemberStudentLeadAddModal,
    AdmninFeedbackModal,
    MemberStudentSendEmail,
    MemberStudentResetEmailModal,
    MemberTeacherModal,
    MemberTeacherChangeRoleModal,
    AdminContentAddModal,
    AnalyticsFinancialExportCharts,
    AnalyticsMarketingExportCharts,
    AnalyticsMarketingApproveRewards,
    ClearScheduledDate,
    DeleteEventModal,
    BlockTeacherModal,
    ListTeachers
  },
  setup() {
    const router = useRouter();
    const { loadServices, userAuthenticated, roles , widthScreen } = usePrivateLayout();
    const { studentInfo, studentBilling } = useInformation();
    const { handleClientLoad } = useCalendar();
    const store = useStore();

    onBeforeMount(() => {
      loadServices();
    });
    
    onMounted(() => {
      if(userAuthenticated.value.role === "student"){
        const scriptEl = document.createElement("script");
        scriptEl.type = "text/javascript";
        scriptEl.async = true;
        scriptEl.src = "//code.jivosite.com/widget/z1wWdnUqoV"
        document.head.appendChild(scriptEl);
      }
      handleClientLoad();
    });
    
    onUpdated(() => {
      if(studentInfo.value.subscription_canceled && 
      userAuthenticated.value.role === "student" &&
      studentBilling.value){
        router.replace({name: 'student-billing'})
      }
      store.dispatch('shared/getCurrentTime')

    })

    return {
      userAuthenticated,
      roles,
      widthScreen,
      currentRoute: computed(() => router.currentRoute.value.name),
      studentBilling
    };
  },
};
</script>
