<template>
  <Dialog header="Header" v-model:visible="feedbackOrNoteConfirm.isOpenConfirm" :modal="true"
    class="modal-custom hidden-content hidden-icons">
    <template #header>
      <div class="header d-flex gap-3">
        <div class="header-logo">
          <img src="/assets/images/logo-icon.png" alt="Bright Lingua" />
        </div>
        <div class="header-texts">
          <h1 class="title text-secondary-1 fs-6 mb-0">WAIT!</h1>
          <h2 class="question text-primary-0 fw-bolder fs-4">
            Are you sure to send the same feedback that you entered previously?
          </h2>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="d-flex flex-row justify-content-end align-items-end gap-2">
        <button :disabled="feedbackOrNoteConfirm.isLoading" class="
          btn
          text-secondary-1
          border-secondary-1
          bg-hover-light-secondary-1
          p-2
          px-5
        " @click="() => {
          sendFeedback()
        }">
          Yes
        </button>
        <button :disabled="feedbackOrNoteConfirm.isLoading" class="
          btn
          text-white
          bg-secondary-1 bg-hover-secondary-1
          p-2
          px-5
          bl-shadow
        " @click="() => toogleModalConfirmFeedback()">
          No
        </button>
      </div>

    </template>
  </Dialog>
</template>

<script>
import useSharedUI from '../../composables/useSharedUI';
import useFeedbackTeacher from '@/modules/teachers/composables/Student/useFeedback';
import useFeedbackStudent from '@/modules/students/composables/MyTeachers/useFeedback';
import { useStore } from 'vuex';
export default {
  name: "ConfirmFeedbackSubmit",
  setup() {
    const store = useStore()
    const { feedbackOrNoteConfirm, toogleModalConfirmFeedback, userAuthenticated } =
      useSharedUI();

    if (userAuthenticated.value.role === 'teacher') {
      const { sendFeedback } = useFeedbackTeacher();
      return {
        feedbackOrNoteConfirm,
        sendFeedback,
        userAuthenticated,
        toogleModalConfirmFeedback,
        store
      };
    } else if (userAuthenticated.value.role === 'student') {
      const { sendFeedback } = useFeedbackStudent();
      return {
        feedbackOrNoteConfirm,
        sendFeedback,
        userAuthenticated,
        toogleModalConfirmFeedback,
        store
      };
    }

  },
};
</script>

