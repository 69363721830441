<template>
  <Dialog
    header="Header"
    v-model:visible="calendarModal.isOpen"
    :showHeader="false"
    contentClass="px-0 m-0 m-0 pb-0  overflow-hidden"
  >
    <div
      class="text-white d-flex align-items-end justify-content-between w-100 px-4 py-2"
      :class="getClasses(calendarModal.data.lesson_start)"
    >
      <div>
        <h2 class="fs-6 mb-1">
          {{ formatTitleDate }}
        </h2>
        <p class="fs-5 m-0 fw-bold">Scheduled class</p>
      </div>
      <div class="text-end">
        <i
          @click="() => toggleModalCalendar(false)"
          class="pi pi-times fs-6 pointer"
        ></i>
        <p class="fs-5 fw-bold">
          {{ calendarModal.data.time_start.substr(0, 5) }} -
          {{ calendarModal.data.time_end.substr(0, 5) }}
        </p>
      </div>
    </div>
    <div class="container-info scroll  m-2 py-2" :class="getScroll(calendarModal.data.lesson_start)">
      <div v-for="(lesson, i) in calendarModal.data.lessons" :key="i">
        <div class="info px-4">
          <div class="info-num">
            <p class="fs-4 fw-bold">{{ i + 1 }}</p>
          </div>
          <div class="info-student">
            <div>
              <p class="fs-6 fw-bold">Student:</p>
              <p class="fs-6 fw-bold">Teacher:</p>
            </div>
            <div>
              <p class="fs-6 fw-bold">{{ lesson.student }}</p>
              <p class="fs-6">{{ lesson.teacher }}</p>
            </div>
          </div>
          <div class="info-process">
           
            <div
              v-if="lesson.status !== 'registred'"
              :class="validateProcess(lesson.status)"
              class="p-1 px-3 rounded-pill text-center w-content ms-auto first-letter-uppercase"
            >
              <p>{{ lesson.status === 'attend' ? 'Attended' : lesson.status}}</p>
            </div>
             <a
              target="_blank"
              :href="lesson.link_zoom"
              v-else
              class="
                btn
                text-white
                bl-shadow
                bg-primary-1 bg-hover-primary-1
                p-2
                px-3
                w-100
              "
            >
              Zoom link
            </a>
          </div>
        </div>
        <hr v-if="calendarModal.data.lessons.length !== i + 1" />
      </div>
    </div>
  </Dialog>
</template>

<script>
import useCalendar from "@/modules/admin/composables/Calendar/useCalendar";
import formatDate from "@/shared/utils/changeTexts/formatDate";
import { useStore } from 'vuex'
import { computed } from "vue";
import moment from "moment";

export default {
  setup() {
    const { calendarModal, toggleModalCalendar } = useCalendar();
    /* student orange and turquoise */
    const store = useStore()

    const validateProcess = (process) => {
      const createClass = (process_) => {
        return `bg-light-feedback-${process_} border-feedback-${process_} text-neutro`;
      };
      if (process.toLowerCase() === "canceled") {
        return createClass("error");
      } else if (process.toLowerCase() === "attend") {
        return createClass("success");
      } else if (process.toLowerCase() === "absent") {
        return createClass("warning");
      }
    };

    const getClasses = (date) => {
      const actualDate = new Date(store.getters['shared/currentTimeTimezone']);
      const dateLesson = new Date(date);
      if (dateLesson > actualDate) {
        return "bg-accent-6";
      } else if (
        actualDate.getFullYear() === dateLesson.getFullYear() &&
        actualDate.getMonth() === dateLesson.getMonth() &&
        actualDate.getDate() === dateLesson.getDate()
      ) {
        return "bg-secondary-1";
      } else {
        return "bg-accent-1";
      }
    };


    const getScroll = (date) => {
      const actualDate = new Date(store.getters['shared/currentTimeTimezone']);
      const dateLesson = new Date(date.replace(/-/g, "/"));
      if (dateLesson > actualDate) {
        return "scroll-yellow";
      } else if (
        actualDate.getFullYear() === dateLesson.getFullYear() &&
        actualDate.getMonth() === dateLesson.getMonth() &&
        actualDate.getDate() === dateLesson.getDate()
      ) {
        return "scroll-secondary";
      } else {
        return "scroll-turquoise";
      }
    };

    const formatTitleDate = computed(() => {
      const date = moment(calendarModal.value.data.lesson_start).format('MMMM, dddd, Do')
      return date
    })

    return {
      getScroll,
      getClasses,
      formatDate,
      validateProcess,
      calendarModal,
      toggleModalCalendar,
      formatTitleDate
    };
  },
};
</script>

<style lang="scss" scoped>
.container-info {
  max-height: 18rem;
  overflow: auto;
}
.info {
  display: grid;
  grid-template-columns: 2rem 1fr 8em;
  align-items: center;
  gap: 10px;
  &-student {
    display: grid;
    grid-template-columns: 5rem 1fr;
  }
}
</style>
