<template>
  <Dialog
    header="Header"
    v-model:visible="emailResetModal.isOpen"
    :modal="true"
    class="modal-custom hidden-content hidden-icons"
  >
    <template #header>
      <div class="header d-flex gap-3">
        <div class="header-logo">
          <img src="/assets/images/logo-icon.png" alt="Bright Lingua" />
        </div>
        <div class="header-texts">
          <h1 class="title text-secondary-1 fs-6 mb-0">CAREFUL!</h1>
          <h2 class="question text-primary-0 fw-bolder fs-4">
            Are you sure to reset password?
          </h2>
          <p class="fs-6 lh-sm">
            If you say yes, a notification email will be sent to the selected
            users
          </p>
        </div>
      </div>
    </template>
    <template #footer>
      <button
        :disabled="isSending"
        :loading="isSending"
        class="
          btn
          text-secondary-1
          border-secondary-1
          bg-hover-light-secondary-1
          p-2
          px-5
        "
        @click="sendEmailsReset"
      >
        Yes
      </button>
      <button
        :disabled="isSending"
        class="
          btn
          text-white
          bg-secondary-1 bg-hover-secondary-1
          p-2
          px-5
          bl-shadow
        "
        @click="cancelEmailResetModal(false)"
      >
        No
      </button>
    </template>
  </Dialog>
</template>

<script>
import useResetEmail from "@/modules/admin/composables/Members/useResetEmail";
export default {
  name: "ResetEmailModal",
  setup() {
    const { emailResetModal, cancelEmailResetModal, resetPasswordEmails , sendEmailsReset , isSending } =
      useResetEmail();

    return {
      emailResetModal,
      isSending,
      sendEmailsReset,
      cancelEmailResetModal,
      resetPasswordEmails,
    };
  },
};
</script>
