<template>
  <Dialog
    header="Header"
    class="modal-custom modal-big btns-max hidden-icons header-blue bg-white"
    v-model:visible="approveRewardsModal.isOpen"
    :modal="true"
    :draggable="false"
    contentClass="px-0 m-3 mb-2 "
  >
    <!-- contentClass="px-0 mx-0" -->
    <template #header>
      <h1 class="bg-primary-1 fs-5 text-start text-white w-100 mb-0">
        Approve rewards
      </h1>
    </template>
    <div class="body px-4 pt-3">
      <div class="d-flex align-items-center justify-content-between">
        <div>
          <h1 class="fs-4 text-primary-1 mb-1">
            Student’s {{ approveRewardsModal.dataSelect.name }}
            {{ approveRewardsModal.dataSelect.last_name }}
          </h1>
          <p class="text-primary-1 fs-6">
            {{ approveRewardsModal.dataSelect.email }}
          </p>
        </div>
        <button
          class="
            btn
            text-secondary-1
            border-secondary-1
            bg-hover-light-secondary-1
            py-2
            px-4
          "
          @click="
            () => {
              goToPage({
                id: approveRewardsModal.dataSelect.id,
                name: 'admin-member-student-profile',
              });
              toggleApproveRewardsModal(false);
            }
          "
        >
          See profile
        </button>
      </div>
      <div class="px-2 mt-4">
        <div class="field-blue w-100 col-2">
          <label for="">Claimed</label>
          <Calendar
            :disabled="true"
            dateFormat="mm.dd.yy"
            :model-value="approveRewardsModal.dataSelect.claimend"
            class="w-100"
            placeholder="MM/DD/YY"
          />
        </div>
        <div class="field-blue w-100 col-2">
          <label for="">Renewal</label>
          <Calendar
            :disabled="true"
            class="w-100"
            placeholder="MM/DD/YY"
            dateFormat="mm.dd.yy"
            :model-value="approveRewardsModal.dataSelect.renewal"
          />
        </div>
        <div class="field-blue w-100 col-2">
          <div class="d-flex gap-2 flex-column">
            <label for="">Reward</label>
            <div
              class="w-content py-2 px-4 rounded-pill text-white bg-accent-1 first-letter-uppercase"
            >
              <span>{{ approveRewardsModal.dataSelect.reward }}</span>
            </div>
          </div>

          <iframe
            v-if="getUrl(approveRewardsModal.dataSelect.link_video).success"
            class="mt-3"
            height="210"
            width="100%"
            :src="
              getUrl(approveRewardsModal.dataSelect.link_video).url +
              '?autoplay=1&mute=1'
            "
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            frameborder="0"
            allowfullscreen
          ></iframe>
        </div>
        <div class="field-blue w-100 col-2">
          <label for="">Approve reward</label>
          <div class="d-flex gap-3 justify-content-start">
            <div
              class="
                form-check
                d-flex
                gap-3
                align-items-center
                justify-content-start
              "
            >
              <input
                class="form-check-input"
                :value="2"
                type="radio"
                name="state"
                v-model="payload.approve_reward"
              />
              <label class="form-check-label">Yes</label>
            </div>
            <div
              class="
                form-check
                d-flex
                gap-3
                align-items-center
                justify-content-start
              "
            >
              <input
                class="form-check-input"
                :value="3"
                type="radio"
                name="state"
                v-model="payload.approve_reward"
              />
              <label class="form-check-label">No</label>
            </div>
          </div>
          <!-- <Calendar class="w-100" placeholder="MM/DD/YY" /> -->
        </div>
      </div>
    </div>
    <template #footer>
      <div class="d-flex gap-2 justify-content-end">
        <button
          :disabled="loadingApproval"
          class="
            btn
            text-secondary-1
            border-secondary-1
            bg-hover-light-secondary-1
            py-2
            px-4
          "
          @click="toggleApproveRewardsModal(false)"
        >
          Cancel
        </button>
        <button
          :disabled="loadingApproval"
          @click="approval"
          class="
            btn
            text-white
            bg-secondary-1 bg-hover-secondaty-1
            bl-shadow
            py-2
            shadow
            px-4
          "
        >
          Approve
        </button>
      </div>
    </template>
  </Dialog>
</template>

<script>
import useRewards from "@/modules/admin/composables/Analitycs/Marketing/useRewards";
import useStudents from "@/modules/admin/composables/Members/Students/useStudents";
import getYtUrlId from "@/shared/utils/getters/getYtUrlId";

export default {
  name: "ApproveRewardsModal",
  setup() {
    const {
      approveRewardsModal,
      toggleApproveRewardsModal,
      payload,
      approval,
      loadingApproval,
    } = useRewards();
    const { goToPage } = useStudents();

    const getUrl = (link) => {
      const res = {
        url: "",
        success: false,
      };
      const { status, id } = getYtUrlId(link);
      res.success = status === "success";

      if (res.success) {
        res.url = "https://www.youtube.com/embed/" + id;
      }

      return res;
    };

    return {
      approveRewardsModal,
      approval,
      toggleApproveRewardsModal,
      goToPage,
      payload,
      loadingApproval,

      getUrl,
    };
  },
};
</script>

<style lang="scss" scoped>
.col-2 {
  display: grid;
  grid-template-columns: 10rem 1fr;
  gap: 1rem;
  margin-bottom: 1.5rem;
  &:last-child {
    margin-bottom: 0rem;
  }
}
</style>
