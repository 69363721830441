<template>
  <Dialog
    header="Header"
    class="modal-custom modal-medium modal-w-icon bg-white"
    v-model:visible="isTermsOpen"
    :modal="true"
    :draggable="false"
    contentClass="px-2 mx-3 mb-4"
    @update:visible="toggleTerms(false)"
  >
    <template #header>
      <h1 class="fs-3 fw-bold text-center text-primary-1 w-100 mb-0">
        TERMS AND CONDITIONS
      </h1>
    </template>
    <div class="body px-4">
      <b>Welcome to Bright Lingua!</b>
      <p>This website is owned and operated by Loritos Learning INC, hereinafter referred to in this document as "Bright Lingua".
      <br>
      These are the terms and conditions for:
      <br>
      <ul>
        <li>
          <a href="https://www.brightlingua.com">https://www.brightlingua.com</a>
        </li>
      </ul>
      By using the website, you agree to be bound by these terms and conditions and our privacy policy. In these terms and conditions, the words "website" refer to the Bright Lingua website, "we", "us", "our" and "Bright Lingua" refer to Bright Lingua and "you" and "user" refer to you, the Bright Lingua user.
      <br>
      The following terms and conditions apply to your use of the website. This includes the mobile and tablet versions, as well as any other versions of Bright Lingua accessible via desktop, mobile, tablet, social media or other devices.
      <br>
      PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY BEFORE USING, POSTING INFORMATION ON, OR OBTAINING ANY SERVICES FROM THE WEBSITE.
      </p>
      <br>
      <b>1. ACCEPTANCE OF TERMS</b>
      <p>This agreement sets forth the legally binding terms for your use of Bright Lingua. By registering and using the website, you agree to be bound by this agreement. If you do not agree to the terms of this agreement, you must not use the website and stop using the service immediately. We may modify this agreement from time to time, and such modification will be effective when posted on the website. You agree to be bound by any modifications to these terms and conditions when you use Bright Lingua after such modification is posted; therefore, it is important that you review this agreement regularly.
      <br>
      Use of the website is prohibited for children under the age of 13. In the case of children under 18 and over 13, it is the responsibility of parents and legal guardians to determine whether use of the website or any of the content and functionality available on the website is appropriate for their child or minor in custody.
      <br>
      You represent and warrant that all registration information you submit is accurate and truthful; and that your use of the website does not violate any applicable law or regulation. Bright Lingua may, in its sole discretion, refuse to offer services to any user and change its eligibility criteria at any time. This provision is void where prohibited by law and the right to access the service and the website is revoked in such jurisdictions.
      <br>
      By using the website, you represent and warrant that you have full right, power and authority to enter into this agreement and to fully perform all of your obligations hereunder. You further represent and warrant that you have no legal incapacity or contractual restriction that would prevent you from entering into this agreement.
      </p>
      <br>
      <b>2. ACCOUNT</b>
      <p>
        When registering on the website, the user must choose a password and may be asked for additional information such as email address. You are responsible for maintaining the confidentiality of your password and account information, and are fully responsible for all activities that occur under your password or account. You agree to (a) immediately notify Bright Lingua of any unauthorized use of your password or account or any other breach of security, and (b) ensure that you log out of your account at the end of each session. You may never use another user's account without Bright Lingua's prior authorization. Bright Lingua will not be liable for any loss or damage arising from your breach of this agreement.
        <br>
        Users can cancel their accounts at any time and for any reason through the user's account settings. Such cancellation will only result in the deletion of the account and the deletion of all personal data transferred to Bright Lingua.
        <br>
        Bright Lingua reserves the right to terminate your account or your access immediately, with or without notice, and without liability to you, if Bright Lingua believes that you have breached any of these terms, provided Bright Lingua with false or misleading information, or interfered with another's use of the website or the service.
      </p>
      <br>
      <b>3. NOTIFICATIONS</b>
      <p>By providing Bright Lingua with your email address and phone number, you agree that we may use your email address to send you notifications about Bright Lingua. We may also use your email address and phone number to send you notifications via SMS messages, push notifications and other messages, such as changes to service features, news and special content. If you do not wish to receive these emails, SMS messages or phone calls, you may opt-out of receiving them by sending your request through our contact information or by using the "unsubscribe" option at the bottom of each email. Opting out may prevent you from receiving emails and SMS messages about updates, news or special content.</p>
      <br>
      <b>4. SUBSCRIPTIONS</b>
      <p>
        Users will be able to access our resources and schedule online classes through monthly billing subscriptions. Users will be able to access a 5-day trial period for a $1.00 fee. At the end of the 5-day trial period, users will automatically upgrade to a monthly billing subscription unless the subscription is canceled during the 5-day trial period. Users may cancel the trial period at any time through the user account settings in the billing section. Please note that the 5-day trial period includes Saturdays, Sundays and holidays.
        <br>
        Once the 5-day period has elapsed, the user will be automatically billed for the monthly subscription and the corresponding subscription fee will be billed automatically. Bright Lingua will send a confirmation email to confirm payment of the subscription. This confirmation email will be produced automatically so that the user will have confirmation of the payment and the start of the subscription. If the user does not receive the purchase and subscription start confirmation email, it may have been sent to their spam folder. This subscription billing confirmation email can be sent through our payment processor Stripe.
        <br>
        Bright Lingua may cancel the sale of any subscription and may change or discontinue the availability of subscriptions at any time at its sole discretion. If a subscription purchase is canceled, the payment made for the subscription will be refunded in the applicable billing period. This does not affect your statutory rights.
        <br>
        Subscriptions include automatic recurring payments. You authorize Bright Lingua to renew your subscription and to charge you periodically and progressively on each billing date. The subscription billing date is the date on which you purchase the subscription and make the first payment.  The billing date of the subscription will be the 6th day after the end of the 5-day trial period and from the 6th day the monthly billing period will start. On the corresponding billing date, you will be automatically charged the corresponding monthly subscription fee in advance. The subscription will remain active until you cancel it or we terminate it. You must cancel your subscription before it renews to avoid the next billing period.
        <br>
        Subscriptions will be automatically billed on each billing date unless canceled prior to the next billing period. Users can cancel subscriptions through the user account settings in the billing section and the subscription will be canceled for the next billing period. If a subscription is canceled, the user may continue to use the website payment features for the subscription until the next billing period, at which time the subscription and access to the website payment features will be canceled.
        <br>
        In cases where the user or student wishes to reactivate the subscription, they may reactivate the subscription by making the corresponding subscription payment (119 USD). The subscription will be automatically billed on each monthly billing date, which will be the date or day on which the user reactivates the subscription and makes the payment.
      </p>
      <br>
      <b>5. PAYMENTS</b>
      <p>
        Subscriptions and services can be paid through the following payment methods:
        <br>
        <ul>
          <li>Credit/debit card (Visa, Mastercard, American Express, Discover)</li>
        </ul>
        Payments will be processed through our payment processor Stripe. The subscription payment (119 USD) will be automatically charged to your credit/debit card on the 6th day after the end of the 5-day trial period and from the 6th day onwards will be billed monthly on each billing date automatically. Once each transaction is processed, we will send an electronic receipt to the user's email address.
        <br>
        If you find any inconsistencies in your billing, please contact us via our contact details or you can make a complaint via the Buyer service of the relevant payment website.
        <br>
        If your card is declined, you will receive an error message. No payment will be charged to your card and no order will be processed. There may be a pending transaction on your account until your card issuing bank withdraws the authorization. This usually takes 2 to 5 working days. Your card may be declined for a number of reasons, such as insufficient funds, AVS (Address Verification System) mismatch or you have entered an incorrect security code.
        <br>
        If your payment is declined, you will need to provide an alternative payment method or provide another card on which the payment can be charged and processed.
        <br>
        Your payment details will be treated and retained securely and for the sole purpose of processing the purchase of the subscriptions. Bright Lingua reserves the right to engage any payment website available on the market, which processes your data for the sole purpose of processing the purchase of the subscriptions.
      </p>
      <br>
      <b>6. CLASS SLOTS</b>
      <p>
        Students or users will be able to schedule classes on the platform through slots. Students may schedule 1 or more classes in a row in each slot. In cases where the student schedules 2 or more classes in a single slot, the maximum interval or waiting time allowed to enter the next class will be 10 minutes. Please note that if a user schedules two or more classes/slots in a row and the user doesn't show up after 10 minutes or doesn't show up at all for the first scheduled slot,  Bright Lingua will automatically cancel the following slots/classes scheduled. Users are welcome to reschedule for another day/time.
        <br>
        Students may only schedule classes up to 15 minutes prior to the start of each class. If there is less than 15 minutes before the start of the class, the student will not be able to schedule the class and will have to schedule the next class or another class at another time.
        <br>
        Students may cancel classes up to 15 minutes prior to the start of the class. If it is less than 15 minutes before the start of the class, the student will not be able to cancel the class. Please note that the cancellation only applies to each class and not to each slot of classes. If the student has several classes scheduled in one slot, the student must cancel each class one by one.
      </p>
      <br>
      <b>7. DISCLAIMER</b>
      <p>
        By accessing the website and purchasing subscriptions, the user accepts personal responsibility for the results of using the content and resources available in the subscriptions and online classes. The user accepts and represents that Bright Lingua does NOT guarantee positive or negative results from purchasing subscriptions, using the available resources and receiving the online classes. The user also agrees that Bright Lingua does not guarantee any results from the use of the information and content available on the website in general. Bright Lingua provides resources and content for informational and educational purposes only. You acknowledge that your ultimate success or failure will be the result of your own efforts, your particular situation and a variety of other circumstances beyond Bright Lingua's control. Your use or misuse of any content or information obtained through subscriptions and online classes is at your own risk and responsibility. Bright Lingua does not accept any claims for the use or misuse of information obtained through the subscriptions and online classes by users.
        <br>
        Promotional videos submitted by students may be used by Bright Lingua for promotional purposes on Bright Lingua's social media channels. In cases where the student submits their promotional video and requests the video reward, the student authorizes the use of their image and the use of the video for promotional and advertising purposes by Bright Lingua.
      </p>
      <br>
      <b>8. LICENSE TO USE THE PLATFORM</b>
      <p>
        Bright Lingua grants you a personal, worldwide, royalty-free, non-assignable, non-exclusive license to use the website that Bright Lingua provides to you as part of the services. This license is for the sole purpose of allowing you to use and enjoy the benefits of the services provided by Bright Lingua, in the manner permitted by these terms. You may not copy, modify, distribute or sell any part of our website or services without our permission.
        <br>
        You agree not to use the website and services in a negligent, fraudulent or unlawful manner. You also agree not to engage in any conduct or action that may damage the image, interests or rights of the Bright Lingua website or third parties.
        <br>
        Bright Lingua reserves the right to terminate the user's access immediately, with or without notice, and without liability to the user, if Bright Lingua believes that the user has violated any of these terms or has interfered with the use of the website or the service by others.
      </p>
      <br>
      <b>9. COPYRIGHT</b>
      <p>
        The content available on the website and subscriptions is the exclusive property of Loritos Learning INC. Loritos Learning INC. owns all text, images, music, videos, software, blog entries, reports and other materials contained on the website and our services. No content or calculator available on the website and our services may be duplicated, distributed or used for any unauthorized purpose without the express written consent of Loritos Learning INC. Any unauthorized use of the content and calculators available on the website and our services may give rise to claims and lawsuits for copyright infringement by Loritos Learning INC.
        <br>
        All Bright Lingua materials, including, without limitation, names, logos, trademarks, images, text, columns, graphics, videos, photographs, illustrations, software and other items, are protected by copyrights, trademarks and/or other intellectual property rights owned and controlled by Bright Lingua or by third parties who have licensed or provided their material to the website. You acknowledge and agree that all Bright Lingua materials are made available for your limited, non-commercial, personal use. Except as specifically provided herein. No material may be copied, reproduced, republished, sold, downloaded, posted, transmitted or distributed in any way, or otherwise used for any purpose, by any person or entity, without the prior express permission of Bright Lingua. You may not add to, delete, distort or otherwise modify the material. Any unauthorized attempt to modify any material, to defeat or circumvent any security feature, or to use Bright Lingua or any portion of the material for any purpose other than its intended purpose is strictly prohibited.
      </p>
      <br>
      <b>10. COPYRIGHT INFRINGEMENT</b>
      <p>
        Bright Lingua will respond to all inquiries, complaints and claims regarding alleged infringement for failure to comply with or violation of the provisions contained in the Digital Millennium Copyright Act (DMCA). Bright Lingua respects the intellectual property of others, and expects users to do the same.  If you believe, in good faith, that any material provided on or in connection with the website infringes your copyright or other intellectual property right, please send us your copyright infringement request pursuant to Section 512 of the Digital Millennium Copyright Act (DMCA), via our contact information, with the following information:
        <br>
        <ul>
          <li>Identification of the intellectual property right that is allegedly infringed. All relevant registration numbers or a statement of ownership of the work should be included.</li>
          <li>A statement that specifically identifies the location of the infringing material, in sufficient detail so that Bright Lingua can find it on the website. </li>
          <li>Your name, address, telephone number and email address.</li>
          <li>A statement by you that you have a good faith belief that use of the allegedly infringing material is not authorized by the copyright owner, or its agents, or the law.</li>
          <li>A statement by you, made under penalty of perjury, that the information in your notice is accurate and that you are the copyright owner or authorized to act on the copyright owner's behalf.</li>
          <li>An electronic or physical signature of the copyright owner or the person authorized to act on the copyright owner's behalf.</li>
        </ul>
      </p>
      <br>
      <b>11. PERSONAL INFORMATION</b>
      <p>Any personal information you provide in connection with your use of the website will be used in accordance with our privacy policy. Please refer to our privacy policy.</p>
      <br>
      <b>12. PROHIBITED ACTIVITIES</b>
      <p>
        The content and information available on the website (including, but not limited to, data, information, text, music, sound, photos, graphics, video, maps, icons or other material), as well as the infrastructure used to provide such content and information, is owned by Bright Lingua or licensed to Bright Lingua by third parties. For all content other than your own, you agree not to modify, copy, distribute, transmit, transmit, display, perform, reproduce, publish, license, create derivative works from, transfer, or sell or resell any information, software or services obtained from or through the website. In addition, the following activities are prohibited:
        <br>
        <ul>
          <li>It is not allowed for a user to use the trial period indefinitely, creating or changing emails to access the service indefinitely and inappropriately.</li>
          <li>Please note that scheduling classes through the platform constitutes a commitment on the part of the student. It is not allowed for the student or user to schedule different classes repeatedly and finally show up for only one or not show up for any class at all. Our teachers' time is valuable and such conduct may cause discomfort to the teachers.</li>
          <li>Inappropriate, rude, obscene, offensive or any behavior that affects the proper progress of the class by users or students against the teacher or any third party during class is not allowed. The teacher may terminate any class if students or users present inappropriate behavior or conduct and this will not result in any refund to the user.</li>
          <li>The user's account is personal and non-transferable. The user may not share the user account with any third party.</li>
          <li>Use the services or content for any commercial purpose, outside the scope of the commercial purposes explicitly permitted in this agreement and related guidelines made available by Bright Lingua.</li>
          <li>Access, monitor, reproduce, distribute, transmit, broadcast, stream, display, sell, license, copy or otherwise exploit any content of the services, including, but not limited to, the use of any robot, spider, scraper or other automated means or any manual process for any purpose not in accordance with this agreement or without our express written permission.</li>
          <li>Violate the restrictions of any robot exclusion header on the website or circumvent or circumvent other measures employed to prevent or limit access to the website.</li>
          <li>Take any action that imposes, or may impose, in our discretion, an unreasonable or disproportionately large load on our infrastructure.</li>
          <li>Establish a deep link to any part of the website for any purpose without our express written permission.</li>
          <li>Attempt to modify, translate, adapt, edit, decompile, disassemble or reverse engineer any software program used by Bright Lingua.</li>
          <li>Circumvent, disable or otherwise interfere with security-related features of the website or features that prevent or restrict the use or copying of any content.</li>
        </ul>
        Please note that any of these prohibited behaviors may result in the suspension of the user's account without the user being eligible for a refund for paid services.
      </p>
      <br>
      <b>13. DISCLAIMER OF WARRANTIES</b>
      <p>
        Due to the nature of the Internet, Bright Lingua provides and maintains the website on an "as is," "as available" basis and does not promise that use of the website will be uninterrupted or error-free. We are not liable to you if we are unable to provide our internet services for any reason beyond our control.
        <br>
        Our website may occasionally contain links to other websites that are not under our control or maintained by us. These links are provided solely for your convenience and we are not responsible for the content of those websites.
        <br>
        Except as provided above, we cannot provide any other warranties, conditions or other terms, express or implied, statutory or otherwise, and all such terms are hereby excluded to the fullest extent permitted by law.
        <br>
        You shall be liable for any breach of these terms by you and, if you use the website in breach of these terms, you shall be liable for and shall reimburse Bright Lingua for any loss or damage caused as a result.
        <br>
        Bright Lingua shall not be liable for any amount for breach of any obligation under this agreement if such breach is caused by the occurrence of any unforeseen event beyond its reasonable control, including, without limitation, internet outages, communications outages, fire, flood, war or acts of God.
        <br>
        These conditions do not affect your statutory rights as a consumer, which are available to you.
        <br>
        Subject to the foregoing, and to the maximum extent permitted by law, Bright Lingua excludes liability for any loss or damage of any kind whatsoever arising, including without limitation any direct, indirect or consequential loss, whether or not arising out of any problem which you notify Bright Lingua of, and Bright Lingua shall have no liability to pay any money by way of compensation, including without limitation any liability in relation to:
        <br>
        <ul>
          <li>Any incorrect or inaccurate information on the website.</li>
          <li>Infringement by any person of any Intellectual Property Rights of any third party caused by your use of the website.</li>
          <li>Any loss or damage resulting from your use of or inability to use the website or resulting from unauthorized access to or alteration of your transmissions or data in circumstances beyond our control.</li>
          <li>Any loss of profits, wasted expenditure, corruption or destruction of data or any other loss that does not arise directly from something we have done wrong.</li>
          <li>Any amount or kind of loss or damage due to viruses or other malicious software that may infect a user's computer equipment, software, data or other property caused by persons accessing or using content on the website or by transmissions through emails or attachments received from Bright Lingua.</li>
          <li>All representations, warranties, conditions and other terms that would, but for this notice, be in effect.</li>
        </ul>
      </p>
      <br>
      <b>14. ELECTRONIC COMMUNICATIONS</b>
      <p>Bright Lingua will not accept any liability for failed, partial or garbled computer transmissions, for any breakdown, failure, connection or availability of computer, telephone, network, electronic or Internet hardware or software, for the acts or omissions of any user, for the accessibility or availability of the Internet or for traffic congestion or for any unauthorized human act, including any error or mistake.</p>
      <br>
      <b>15. THIRD PARTIES</b>
      <p>Through your use of the website and services you may encounter links to third party sites or be able to interact with third party sites. These third parties may charge a fee for the use of certain content or services provided on or through their websites. Therefore, you should investigate as you deem necessary or appropriate before proceeding with any transaction with any third party to determine whether a fee will be incurred. Where Bright Lingua provides details of fees or charges for such third-party content or services, such information is provided for convenience and information purposes only. Any interaction with third party sites and applications is at your own risk. You expressly acknowledge and agree that Bright Lingua is in no way responsible for such third-party websites.</p>
      <br>
      <b>16. INDEMNIFICATION</b>
      <p>
        You agree to defend and indemnify Bright Lingua from and against any claims, causes of action, demands, recoveries, losses, damages, fines, penalties or other costs or expenses of any kind or nature, including, but not limited to, reasonable legal and accounting fees, brought by third parties as a result of:
        <br>
        <ul>
          <li>Your breach of this Agreement or the documents referenced herein.</li>
          <li>Your violation of any law or the rights of a third party.</li>
          <li>Your use of the Bright Lingua website.</li>
        </ul>
      </p>
      <br>
      <b>17. CHANGES AND TERMINATION</b>
      <p>We may change the website and these terms at any time, at our sole discretion and without notice. You are responsible for keeping yourself informed of these terms. Your continued use of the website constitutes your acceptance of any changes to these terms and any changes will supersede all previous versions of the terms. Unless otherwise specified, all changes to these terms apply to all users. In addition, we may terminate our agreement with you under these terms at any time by notifying you in writing (including by email) or without notice.</p>
      <br>
      <b>18. ASSIGNMENT</b>
      <p>This agreement and any rights and licenses granted hereunder may not be transferred or assigned by you, but may be assigned by Bright Lingua without restriction.</p>
      <br>
      <b>19. INTEGRATION CLAUSE</b>
      <p>This agreement, together with the privacy policy and any other legal notices posted by Bright Lingua, shall constitute the entire agreement between you and Bright Lingua and shall govern your use of the website.</p>
      <br>
      <b>20. DISPUTES</b>
      <p>
        You agree that any dispute, claim or controversy arising out of or relating to these terms and conditions, or the breach, termination, enforcement, interpretation or validity thereof or the use of the website, shall be resolved by binding arbitration between you and Bright Lingua, provided that each party retains the right to bring an individual action in a court of competent jurisdiction.
        <br>
        In the event a dispute arises in connection with your use of the website or breach of these terms and conditions, the parties agree to submit their dispute to arbitration resolution before a reputable arbitration organization as mutually agreed by the parties and in accordance with applicable commercial arbitration rules.
        <br>
        You agree to initiate a formal dispute proceeding by sending us a communication through our contact information. Bright Lingua may choose to send you a written offer after receiving your initial communication. If we offer and send you a settlement offer and you do not accept the offer, or we are unable to resolve your dispute satisfactorily and you wish to continue the dispute process, you must initiate the dispute resolution process before an accredited arbitration organization and file a separate Demand for Arbitration. Any award rendered by the arbitration tribunal shall be final and conclusive on the parties.
        <br>
        To the fullest extent permitted by law, you agree that you will not file, join or participate in any class action lawsuit in connection with any claim, dispute or controversy that may arise in connection with your use of the website.
        <br>
        The courts of the United States, specifically the courts located in the State of Michigan, shall have jurisdiction over any dispute, controversy or claim relating to Bright Lingua and its business operations. Any such dispute or controversy shall be brought and resolved in the courts of the United States, specifically the courts located in the State of Michigan.
      </p>
      <br>
      <b>21. FINAL PROVISIONS</b>
      <p>
        Although our services and website are available globally, these terms and conditions are governed by the laws of the United States. Use of the Bright Lingua website is unauthorized in any jurisdiction that does not give effect to all provisions of these terms and conditions.
        <br>
        Our compliance with these terms is subject to existing laws and legal process, and nothing contained in these terms limits our right to comply with law enforcement or other governmental or legal requests or requirements relating to your use of our website or information provided to or collected by us in connection with such use.
        <br>
        If any section of these terms is held invalid, illegal or unenforceable, the validity, legality and enforceability of the remaining provisions shall not in any way be affected or impaired. Our failure to enforce or delay in enforcing any provision of these terms at any time does not waive our right to enforce the same or any other provision in the future.
        <br>
        Any rights not expressly granted herein are reserved.
      </p>
      <br>
      <b>22. CONTACT INFORMATION</b>
      <p>
        If you have questions or concerns about these terms, please contact us through our contact page or by using the contact information below:
        <br>
        <small>
          <b>Bright Lingua.</b>
        </small>
        <br>
        <small>
          <b>Email:  support@brightlingua.com</b>
        </small>
      </p>
    </div>
    <template>
      <h1 class="fs-3 fw-bold text-center text-primary-1 w-100 mb-0">
        TERMS AND CONDITIONS
      </h1>
    </template>
    <div class="body px-4">
      <b>1. ACCEPTANCE OF TERMS</b>
      <p>
        Due to the nature of our products and services (digital products and services), all subscription purchases are final and non-refundable.
        <br>
        Users will be able to access a 5-day trial period for a fee of $1. At the end of the 5-day trial period, the subscription will be automatically upgraded to a monthly billing subscription. Users will be able to cancel the trial period at any time through the user account settings. Please note that once the trial period ends and the user switches to a monthly billing subscription, no refunds will be issued.
        <br>
        Bright Lingua may also issue a refund if you have been incorrectly charged a subscription fee due to a technical problem with our website or our payment processor. If you encounter any inconsistencies in your billing, please contact the customer service of the applicable payment processor or contact us through our contact information for further information and assistance.
      </p>
      <br>
      <b>2. CANCELLATIONS</b>
      <p>Subscriptions will be automatically billed on each billing date unless canceled prior to the next billing period. The user may cancel the subscription at any time and access to the subscription will continue to be available until the next billing date, when it will be permanently suspended. Subscriptions can be canceled through the user's account settings on the website.</p>
      <br>
      <b>3. CONTACT US</b>
      <p>
        If you have questions or concerns about this refund policy, please contact us through our contact page or through the information below.
        <br>
        <small>
          <b>Bright Lingua.</b>
        </small>
        <br>
        <small>
          <b>Email:  support@brightlingua.com</b>
        </small>
      </p>
    </div>
  </Dialog>
</template>
  
<script>
import useRewards from "@/modules/students/composables/Rewards/useRewards";
export default {
  name: "AdminAddModal",
  setup() {
    const { isTermsOpen, toggleTerms } = useRewards();
    return { isTermsOpen, toggleTerms };
  },
};
</script>

