<template>
  <div
    class="menu-notification text-white p-3 bg-primary-2 mx-4 my-2 h-100 position-relative d-flex flex-column justify-content-center"
    v-if="notificationClass.isOpen && difference.minutes > 0 && difference.minutes <= 5 && difference.hours === 0"
    >
    <header class="fw-bold d-flex justify-content-end close-notification-class end-0 pe-2" >
      <i class="pi pi-times pointer  " @click="toggleNotification(false)"></i>
    </header>
    <div class="bg-transparent text-white pt-1">
      <p class="m-0 text-center">Next class starts in:</p>
      <p class="m-0 text-center">
        {{
          difference.hours > 0
            ? `In ${difference.hours} hr with ${difference.minutes} min`
            : `${difference.minutes} min`
        }}.
      </p>
    </div>
    <footer class="text-center mt-xxl-3">
      <a
        :href="notificationClass.data.link_zoom"
        target="_blank"
        class="btn btn-link text-white fw-bold"
        >Go to my class</a
      >
    </footer>
  </div>
</template>
<script>
import useSharedUI from "@/shared/composables/useSharedUI";
import { watchEffect, ref, onMounted, onUnmounted } from "vue";
import differenceDates from "@/shared/utils/dates/differenceDates";
import { useStore } from "vuex";
import moment from "moment";
export default {
  name: "NotificationClassComponent",
  components: {},
  setup() {
    const { toggleNotification, notificationClass } = useSharedUI();
    const store = useStore();
    const difference = ref({ hours: 0, minutes: 0 });
    difference.value = differenceDates(
      new Date(notificationClass.value.data?.lesson_start?.replace(/-/g, "/")),
      store.getters['shared/currentTimeTimezone']
    );
    const date = ref(new Date())
    const interval = ref(null)

    watchEffect(() => {
      let data = notificationClass.value.data;
      difference.value = differenceDates(
        new Date(data?.lesson_start?.replace(/-/g, "/")),
        store.getters['shared/currentTimeTimezone']
      );
    });

    onMounted(() => {
      interval.value = setInterval(() => {
        const minutes = moment(date.value).format('mm')
        const actual = moment(new Date()).format('mm')

        if(parseInt(minutes) < parseInt(actual)){
          date.value = new Date()
          if(difference.value.minutes >= 0){
            difference.value.minutes -= 1
          }else{
            toggleNotification(false)
          }
        }
      }, 1000);
    })

    onUnmounted(() => {
      clearInterval(interval.value)
    })

    return {
      toggleNotification,
      difference,
      notificationClass,
    };
  },
};
</script>
