const uiSecondaryItemsData = [
  {
    label: "Rewards",
    icon: "star",
    name: "student-rewards",
  },
  {
    label: "Help",
    icon: "help_outline",
    name: "student-help",
  },
];

export default uiSecondaryItemsData;
