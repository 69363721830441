<template>
  <Dialog
    header="Header"
    class="modal-custom modal-big btns-max hidden-icons header-blue bg-white"
    v-model:visible="leadAddModal.isOpen"
    :modal="true"
    :draggable="false"
    contentClass="px-0 mx-0  overflow-hidden"
  >
    <template #header>
      <h1 class="bg-primary-1 fs-5 text-start text-white w-100 mb-0">
        New lead
      </h1>
    </template>
    <div class="body px-4 pt-3">
      <h4 class="text-primary-0 mb-3 fs-5">Basic info</h4>
      <div class="d-grid grid-fields gap-4 mb-0">
        <div class="field-blue">
          <label for="names" class="h6 m-0">Name(s)</label>
          <input-text
            type="text"
            name="name"
            v-model="fields.name"
            :disabled="leadAddModal.save"
          />
        </div>
        <div class="field-blue">
          <label for="last_name" class="h6 m-0">Last name(s)</label>
          <input-text
            type="text"
            name="last_name"
            v-model="fields.last_name"
            :disabled="leadAddModal.save"
          />
        </div>
        <div class="field-blue col-2 w-100">
          <label for="email" class="h6 m-0">Email</label>
          <input-text
            type="email"
            name="email"
            v-model="fields.email"
            :disabled="leadAddModal.save"
          />
        </div>
      </div>
    </div>
    <template #footer>
      <div class="d-flex gap-2 justify-content-end">
        <button
          class="
            btn
            text-secondary-1
            border-secondary-1
            bg-hover-light-secondary-1
            py-2
            px-4
          "
          @click="cancel"
          :disabled="leadAddModal.save"
        >
          Cancel
        </button>
        <button
          class="
            btn
            text-white
            bg-secondary-1 bg-hover-secondaty-1
            bl-shadow
            py-2
            shadow
            px-4
          "
          :disabled="leadAddModal.save"
          @click="save"
        >
          {{ leadAddModal.save ? "Creating ..." : "Create" }}
        </button>
      </div>
    </template>
  </Dialog>
</template>

<script>
import useLeadAdd from "@/modules/admin/composables/Members/Students/useLeadAdd";
export default {
  name: "LeadAddModal",
  setup() {
    return {
      ...useLeadAdd(),
    };
  },
};
</script>

<style lang="scss" scoped>
.grid-fields {
  grid-template-columns: 1fr 1fr;

  & > div {
    display: grid;
    grid-template-columns: 6rem 1fr;
    align-items: center;
  }
  & .col-2 {
    grid-column: 1 / 3;
  }
}
</style>
