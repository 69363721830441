<template>
  <Dialog header="Header" v-model:visible="showDeleteModal" :modal="true"
    class="modal-custom hidden-content hidden-icons">
    <template #header>
      <div class="header d-flex gap-3">
        <div class="header-logo">
          <img src="/assets/images/logo-icon.png" alt="Bright Lingua" />
        </div>
        <div class="header-texts">
          <h1 class="title text-secondary-1 fs-6 mb-0">WAIT!</h1>
          <h2 class="question text-primary-0 fw-bolder fs-4">
            Are you sure you want to change this credit card?
          </h2>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="d-flex flex-row justify-content-end align-items-end gap-2">
        <button class="
          btn
          text-secondary-1
          border-secondary-1
          bg-hover-light-secondary-1
          p-2
          px-5
        " @click="deleteCard">
          Yes
        </button>
        <button class="
          btn
          text-white
          bg-secondary-1 bg-hover-secondary-1
          p-2
          px-5
          bl-shadow
        " @click="toggleDeleteCardModal({ isOpen: false })">
          No
        </button>
      </div>

    </template>
  </Dialog>
</template>

<script>
import usePaymentInfo from "@/modules/students/composables/Billing/usePaymentInfo";
export default {
  name: "PaymentDeleteModal",
  setup() {
    const { showDeleteModal, deleteCard, toggleDeleteCardModal } =
      usePaymentInfo();
    return { showDeleteModal, toggleDeleteCardModal, deleteCard };
  },
};
</script>

