<template>
  <Dialog header="Header" class="modal-custom modal-medium modal-student-feedback bg-white"
    v-model:visible="feedbackStudentOrTeacher.isOpen" :modal="true" contentClass="pb-1 mt-1">
    <template #header>
      <h1 class="fs-2 fw-bold text-center text-secondary-1 w-100 mb-0">
        My answers
      </h1>
    </template>

    <div class="text-center my-2">
      <h4 class="mb-0">{{ feedbackStudentOrTeacher.isStudent ? feedbackStudentOrTeacher.data.teacher : feedbackStudentOrTeacher.data.student }}</h4>
      <p>
        {{
            formatDate(
              feedbackStudentOrTeacher.data.lesson_start_other !== undefined || feedbackStudentOrTeacher.data.lesson_start !== undefined
                ? feedbackStudentOrTeacher.data.lesson_start_other ?? feedbackStudentOrTeacher.data.lesson_start
                : "1990-04-04 05:00:00",
              "complete"
            )
        }}
      </p>
    </div>
    <div class="my-3" v-if="feedbackStudentOrTeacher.data.feedback.length > 0">
      <template v-for="(topic, key, index) in getInfoFeedback()" :key="index">
        <p class="text-secondary-1">{{ key.toUpperCase() }}</p>
        <div v-for="(question, index2) in topic" :key="index2">
          <template v-if="question.type === 'stars'">
            <div class="d-flex align-items-center justify-content-between">
              <p class="fw-bold mb-1">{{ question.question }}</p>
              <rating v-model="question.stars" :cancel="false" :disabled="true" />
            </div>

            <div class="field-blue w-100" v-if="question.answer !== '' && question.answer !== null">
              <Textarea :autoResize="true" :disabled="true" rows="5" :modelValue="question.answer"
                class="w-100 border-r-10 mt-2" placeholder="Give any comment you would like to share. " />
            </div>
          </template>
          <template v-else-if="question.type=='select_true_false'">
            <p class="fw-bold">{{ question.question }}</p>
            <div class="d-flex gap-3 mt-1">
              <div class="d-flex gap-2 align-items-center">
                <radio-button name="assistance" value="Yes" v-model="question.answer" :disabled="true" />
                <label>Yes</label>
              </div>
              <div class="d-flex gap-2 align-items-center">
                <radio-button name="assistance" value="No" v-model="question.answer" :disabled="true" />
                <label>No</label>
              </div>
            </div>
          </template>
            <template v-else>
              <div class="field-blue w-100 mb-3">
                <p class="fw-bold">Any other comments?</p>
                <Textarea :autoResize="true" rows="5" :disabled="true" v-model="question.answer"
                  class="w-100 border-r-10 mt-2" placeholder="Give any comment you would like to share. " />
              </div>
            </template>
        </div>
      </template>
    </div>
    <div class="my-3 text-center" v-else>
        <h4>No comments</h4>
    </div>

  </Dialog>
</template>

<script>
import formatDate from "@/shared/utils/changeTexts/formatDate";
import useStudent from "../../composables/Members/Students/useStudents";
export default {
  name: "FeedbackModal",
  setup() {
    const { feedbackStudentOrTeacher, getInfoFeedback } = useStudent()

    return {
      formatDate,
      feedbackStudentOrTeacher,
      getInfoFeedback
    };
  },
};
</script>
