<template>
  <Dialog header="Header" v-model:visible="showSuscriptionDeleteModal" :modal="true" :draggable="false"
    class="modal-custom hidden-content hidden-icons modal-md">
    <template #header>
      <div class="header d-flex gap-3">
        <div class="header-logo">
          <img src="/assets/images/logo-icon.png" alt="Bright Lingua" />
        </div>
        <div class="header-texts">
          <h1 class="title text-uppercase text-secondary-1 fs-6 mb-0">
            HEADS UP!
          </h1>
          <h2 class="question text-primary-0 fw-bolder fs-4">
            Are you sure to cancel your membership?
          </h2>
          <p class="fs-6 lh-sm">
            If you cancel your membership during the trial period, you can use our program until the trial expires.<br>
            If you are an active member, regardless of when you cancel during your billing cycle, you’ll have access to your paid membership benefits until the end of your ongoing billing cycle.
          </p>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="d-flex flex-row justify-content-end align-items-end gap-2">
        <button :disabled="isCancelSubscription"
          class="btn text-secondary-1 border-secondary-1 bg-hover-light-secondary-1 p-2 px-5"
          @click="cancelSubscription">
          Yes
        </button>
        <button :disabled="isCancelSubscription"
          class="btn text-white bg-secondary-1 bg-hover-secondary-1 p-2 px-5 bl-shadow"
          @click="() => toggleSuscriptionDeleteModal({ isOpen: false })">
          No
        </button>
      </div>

    </template>
  </Dialog>
</template>

<script>
import usePaymentInfo from "@/modules/students/composables/Billing/usePaymentInfo";
export default {
  name: "PaymentSuscriptionDelete",
  setup() {
    const {
      showSuscriptionDeleteModal,
      toggleSuscriptionDeleteModal,
      isCancelSubscription,
      cancelSubscription,
    } = usePaymentInfo();
    return {
      showSuscriptionDeleteModal,
      toggleSuscriptionDeleteModal,
      isCancelSubscription,
      cancelSubscription,
    };
  },
};
</script>
