<template>
  <Dialog
    v-model:visible="toggleModalResetPassword.isOpen"
    :modal="true"
    :breakpoints="{ '960px': '464px', '640px': '464px' }"
    :style="{ width: '464px' }"
    class="hidden-icons"
  >
    <div class="">
      <div class="d-flex gap-2 align-items-center header">
        <div class="header-logo">
          <img src="/assets/images/logo-icon.png" alt="Bright Lingua" />
        </div>
        <div class="d-block">
          <h5 class="text-secondary-1 mb-0">Create your new password</h5>
          <span class="text-primary-1">Reset your default password </span>
        </div>
      </div>
      <div class="body">
        <form
          class="d-flex flex-column gap-2 mt-4"
          @submit.prevent="createPassword"
        >
          <div class="field-blue">
            <password
              :feedback="false"
              name="password"
              placeholder="Password"
              toggleMask
              class="shadow-sm rounded-0"
              required
              autocomplete="on"
              v-model="fields.password"
            />
          </div>

          <div class="form-requirements mt-2" v-if="requirements.length > 0">
            <div
              class="requeriment d-flex gap-2 align-items-center mb-2"
              v-for="(req, index) in requirements"
              :key="index"
            >
              <span
                class="
                  validate
                  d-flex
                  align-items-center
                  justify-content-center
                "
                :class="req.isValidate ? 'validate-fill' : ''"
              >
                <i class="pi pi-check text-secondary-1 fw-bold"></i>
              </span>
              <span>{{ req.text }}</span>
            </div>
          </div>
          <div class="field-blue">
            <password
              :feedback="false"
              name="password_confirmation"
              placeholder="Confirm your password"
              toggleMask
              class="shadow-sm rounded-0"
              required
              autocomplete="on"
              v-model="fields.password_confirmation"
            />
          </div>

          <button
            :disabled="isCreatePassLoading || requirementsValidated < 4"
            type="submit"
            class="
              btn
              text-white
              bg-secondary-1 bg-primary-1
              p-2
              px-5
              w-100
              mt-4
            "
          >
            Create my new password
          </button>
          <div
            :disabled="isCreatePassLoading"
            @click="openHideReset"
            class="
              btn
              text-secondary-1
              border-secondary-1
              bg-hover-light-secondary-1
              p-2
              px-5
              w-100
              mt-1
            "
          >
            Cancel
          </div>
        </form>
      </div>
    </div>
  </Dialog>
</template>

<script>
import useResetPassword from "@/modules/teachers/composables/useResetPassword";

export default {
  setup() {
    return {
      ...useResetPassword(),
    };
  },
};
</script>
