<template>
  <Dialog header="Header" v-model:visible="confirmTour.isOpen" :modal="true"
    class="modal-custom hidden-content hidden-icons">
    <template #header>
      <div class="header d-flex gap-3">
        <div class="header-logo">
          <img src="/assets/images/logo-icon.png" alt="Bright Lingua" />
        </div>
        <div class="header-texts">
          <h1 class="title text-secondary-1 fs-6 mb-0">WAIT!</h1>
          <h2 class="question text-primary-0 fw-bolder fs-4">
            Do you want to take the mini tour?
          </h2>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="d-flex flex-row  justify-content-end align-items-end gap-2">
        <button class="
          btn
          text-secondary-1
          border-secondary-1
          bg-hover-light-secondary-1
          p-2
          px-5
        " @click="() => {
          confirmMiniTour(false)
          store.commit('students/startTour', { isOpen: true })
        }">
          Yes
        </button>
        <button class="
          btn
          text-white
          bg-secondary-1 bg-hover-secondary-1
          p-2
          px-5
          bl-shadow
        " @click="() => confirmMiniTour(false)">
          No
        </button>
      </div>

    </template>
  </Dialog>
</template>

<script>
import usePersonalDetails from '../../composables/StudentProfile/usePersonalDetails';
import { useStore } from 'vuex';
export default {
  name: "ConfirmMiniTour",
  setup() {
    const store = useStore()
    const { confirmMiniTour, confirmTour } =
      usePersonalDetails();
    return {
      confirmMiniTour,
      confirmTour,
      store
    };
  },
};
</script>

