<template>
  <Dialog header="Header" v-model:visible="isOpenListTeachers" :showHeader="false"
    contentClass="px-0 m-0 m-0 pb-0  overflow-hidden">
    <div class="bg-secondary-1 text-white d-flex justify-content-between w-100 px-4 py-2">
      <div>
        <h6 class="mb-1">
          Scheduled class
        </h6>
        <h5 class="m-0 fw-bold">Change to available teacher </h5>
      </div>
      <div class="text-start">
        <h6 class="fw-bold" style="font-size: 11.5px;margin-bottom:11.5px">
          {{ formatDate(dataClass.lesson_start, "changeTeacher") }}
        </h6>
        <h6 class="m-0 text-end">

          {{ dataClass.lesson_start.substr(11, 5) }} - {{ dataClass.lesson_end.substr(11, 5) }}
        </h6>
      </div>
    </div>
    <div class="container-info scroll  m-2 py-2">
      <skeleton v-if="isLoadingAvailableTeachers" borderRadius="10px" height="10rem" class="skeleton-dark" />
      <div v-else-if="!isLoadingAvailableTeachers && availableTeachers.length > 0" v-for="(teacher, i) in availableTeachers" :key="i">
        <div class="px-4">
          <div class="d-flex gap-3 align-items-center">
            <div>
              <RadioButton name="teacher" :value="teacher.id" v-model="teacherSelect" />
            </div>
            <div>
              <p class="fs-6 fw-bold">Teacher:</p>
            </div>
            <div>
              <p class="fs-6">{{ teacher.name }} {{ teacher.last_name }}</p>
            </div>
          </div>
        </div>
        <hr v-if="availableTeachers.length !== i + 1" />
      </div>
      <div class="d-flex justify-content-center align-items-center" v-else>
          <h2>There are no teachers available </h2>
      </div>
      <div class="d-flex justify-content-end mt-3 gap-3">
         <button style="" :disabled="isChangingTeacher" type="button" @click="toggleListTeachers" class="
           btn text-secondary-1 d-flex gap-2 align-items-center border-secondary-1 w-auto bg-hover-light-secondary-1 p-2 px-4
          ">
                Cancel
            </button>
        <button class="btn text-white bg-secondary-1 bg-hover-secondary-1 py-2 px-4 bl-shadow w-auto"
          @click="changeTeacher" :disabled="!teacherSelect || isChangingTeacher">
          Change
        </button>
      </div>
    </div>

  </Dialog>
</template>

<script>
import formatDate from "@/shared/utils/changeTexts/formatDate";
import useScheduledTeacher from "../../../../composables/Members/Teachers/useScheduledTeacher";
export default {
  setup() {
    /* student orange and turquoise */
    const { isOpenListTeachers, dataClass, toggleListTeachers, isLoadingAvailableTeachers, availableTeachers, teacherSelect , changeTeacher , isChangingTeacher } = useScheduledTeacher();
    return {
      formatDate,
      teacherSelect,
      isChangingTeacher,
      changeTeacher,
      isLoadingAvailableTeachers,
      availableTeachers,
      toggleListTeachers,
      dataClass,
      isOpenListTeachers
    };
  },
};
</script>

<style lang="scss" scoped>
.container-info {
  max-height: 18rem;
  overflow: auto;
}

.info {
  display: grid;
  grid-template-columns: 2rem 1fr 8em;
  align-items: center;
  gap: 10px;

  &-student {
    display: grid;
    grid-template-columns: 5rem 1fr;
  }
}
</style>
