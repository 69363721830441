<template>
  <Dialog
    header="Header"
    class="modal-custom modal-big-2 btns-max hidden-icons header-blue bg-white"
    v-model:visible="teacherModal.isOpen"
    :modal="true"
    contentClass="px-0 mx-0 mb-2 overflow-hidden"
  >
    <template #header>
      <h1 class="fs-5 text-start text-white w-100 mb-0">
        {{ teacherModal.status === "add" ? "New teacher" : "" }}
        {{ teacherModal.status === "edit-job" ? "Edit teacher Job" : "" }}
        {{
          teacherModal.status === "edit-profile" ? "Edit teacher profile" : ""
        }}
      </h1>
    </template>
    <div class="body px-4 pt-3">
      <div v-if="currentStep === '1' && teacherModal.status !== 'edit-job'">
        <div>
          <!-- <h1
            class="text-secondary-1 text-uppercase fs-6 mb-1"
            v-if="teacherModal.status === 'add'"
          >
            First step
          </h1> -->
          <h1 class="text-primary-0 fw-bold fs-3">Basic info</h1>
        </div>

        <form class="px-3 py-3 scroll overflow-auto content-fields">
          <div class="field-blue col-1 w-100">
            <label for="names" class="h6 m-0">Name(s)*</label>
            <input-text type="text" name="name" v-model="fields.name" />
          </div>
          <div class="field-blue col-1 w-100">
            <label for="last_name" class="h6 m-0">Last name(s)*</label>
            <input-text
              type="text"
              name="last_name"
              v-model="fields.last_name"
            />
          </div>
          <div class="field-blue col-1 w-100">
            <label for="address" class="h6 m-0">Address</label>
            <input-text type="text" name="address" v-model="fields.address" />
          </div>
          <div class="field-blue col-1 w-100">
            <label for="email" class="h6 m-0">Email*</label>
            <input-text type="email" name="email" v-model="fields.email" />
          </div>
          <div class="grid-2 w-100">
            <div class="field-blue col-1 w-100">
              <label class="h6 m-0">Gender</label>
              <dropdown
                :options="listings.genders"
                optionLabel="value"
                optionValue="value"
                placeholder="Select gender"
                name="gender"
                class="w-100"
                v-model="fields.gender"
              />
            </div>
            <div class="field-blue col-1 col-1- w-100">
              <label class="h6 m-0">Phone</label>
              <div class="d-flex align-items-center gap-2">
                <dropdown
                  :options="listings.countrycodes.data"
                  :loading="listings.countrycodes.isLoading"
                  optionLabel="dial_code"
                  optionValue="id"
                  placeholder="Select code"
                  name="value"
                  :filter="true"
                   @filter="selectCode"
                  class="w-100"
                  v-model="fields.phone_code_id"
                />
                <input-number
                  name="phone"
                  mode="decimal"
                  class="w-100"
                  v-model="fields.cellphone"
                  :useGrouping="false"
                />
              </div>
            </div>
          </div>

          <div class="grid-2 w-100">
            <div class="field-blue col-1 w-100">
              <label class="h6 m-0">Birthday</label>
              <Calendar
                id="icon"
                :showIcon="false"
                class="w-100"
                name="birth"
                dateFormat="mm/dd/yy"
                v-model="fields.birth"
                :maxDate="new Date(store.getters['shared/currentTimeTimezone'])"
              />
            </div>
            <div class="field-blue col-1 col-1- w-100">
              <label class="h6 m-0">Hire date</label>
              <Calendar
                id="icon"
                :showIcon="false"
                class="w-100"
                name="hire_date"
                dateFormat="mm/dd/yy"
                :maxDate="new Date(store.getters['shared/currentTimeTimezone'])"
                v-model="fields.hire_date"
              />
            </div>
             <div class="field-blue col-1 w-100">
              <label class="h6 m-0">Time zone*</label>
              <dropdown
                :options="listings.timesZones.data"
                :loading="listings.timesZones.isLoading"
                optionLabel="timezone_change"
                optionValue="timezone_change"
                placeholder="Select a time zone"
                name="timezone"
                v-model="fields.timezone_change"
                :filter="true"
              />
            </div>
          </div>

          <div class="grid-2 w-100">
            <div class="field-blue w-100">
              <label class="h6 m-0">Insert profile picture</label>
              <div
                class="header-user- header-user-big mx-auto w-100 text-center"
              >
                <div
                  @click="changeAvatar"
                  v-if="!changingPhoto"
                  class="position-relative text-center user-picture pointer"
                >
                  <avatar :image="urlAvatar" shape="circle" class="" />
                  <div
                    class="
                      edit-avatar
                      text-secondary-2
                      align-items-center
                      justify-content-center
                    "
                    :class="[!urlAvatar ? 'edit-show' : '']"
                  >
                    <i class="pi pi-camera"></i>
                    <input
                      type="file"
                      class="d-none"
                      ref="inputFile"
                      @change="onSelectedImage"
                    />
                  </div>
                </div>
                <skeleton
                  v-else
                  borderRadius="50%"
                  height="8rem"
                  width="8rem"
                  class="skeleton-dark m-auto"
                />
                <p class="mt-2 fs-6 text-primary-1 px-5 lh-sm">
                  The image must be 110x110 pixels and in .jpg .png format.
                </p>
              </div>
            </div>
            <div class="field-blue w-100">
              <label class="h6 m-0 mb-2">Insert video presentation</label>
              <input-text
                type="url"
                name="video"
                placeholder="Insert YouTube link"
                v-model="fields.link_video"
              />

              <iframe
                v-if="isShowIframe"
                class="mt-3"
                height="210"
                width="100%"
                :src="
                  'https://www.youtube.com/embed/' +
                  idIframe +
                  '?autoplay=1&mute=1'
                "
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                frameborder="0"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </form>
      </div>

      <div v-if="currentStep === '2' || teacherModal.status === 'edit-job'">
        <div>
          <h1
            class="text-secondary-1 text-uppercase fs-6 mb-1"
            v-if="teacherModal.status === 'add'"
          >
            Second step
          </h1>
          <h1 class="text-primary-0 fw-bold fs-3">Job scheduled</h1>
        </div>
        <div
          class="px-3 py-3 scroll overflow-auto content-fields grid-2 grid-2-"
        >
          <div class="list-days">
            <h1 class="fs-5 mb-3 text-neutro-1 fw-bold">Days</h1>
            <div
              class="
                form-check
                d-flex
                gap-3
                align-items-center
                justify-content-start
                w-100
                mb-3
              "
              v-for="(day, index) in listings.days"
              :key="index"
            >
              <input
                class="form-check-input"
                type="checkbox"
                :value="day.id"
                :checked="fields.days.includes(day.id + '')"
                @change="selectedDay"
              />
              <label class="form-check-label">{{ day.value }}</label>
            </div>
          </div>
          <div>
            <div class="field-blue col-1 w-100">
              <label class="text-neutro-1 fs-5 fw-bold m-0">Time zone*</label>
              <dropdown
                :options="listings.timesZones.data"
                :loading="listings.timesZones.isLoading"
                optionLabel="timezone"
                optionValue="timezone"
                placeholder="Select a time zone"
                name="timezone"
                v-model="fields.timezone"
                :filter="true"
              />
            </div>
            <div>
              <p class="text-neutro-1 fs-5 fw-bold mb-2">Working hours</p>
              <div class="d-flex align-items-center gap-5">
                <div
                  class="
                    form-check
                    d-flex
                    gap-3
                    align-items-center
                    justify-content-start
                    mb-2
                  "
                >
                  <input
                    class="form-check-input"
                    type="radio"
                    name="time"
                    id="full-time"
                    value="1"
                    v-model="currentTime"
                  />
                  <label
                    class="form-check-label fs-5 text-neutro-1 fw-bold"
                    for="full-time"
                  >
                    Full-Time
                  </label>
                </div>
                <div
                  class="
                    form-check
                    d-flex
                    gap-3
                    align-items-center
                    justify-content-start
                    mb-2
                  "
                >
                  <input
                    class="form-check-input"
                    v-model="currentTime"
                    value="2"
                    type="radio"
                    name="time"
                  />
                  <label
                    class="form-check-label fs-5 text-neutro-1 fw-bold"
                    for="part-time"
                  >
                    Part-Time
                  </label>
                </div>
              </div>

              <div class="mr-4 mt-3" v-if="currentTime">
                <p class="fs-5 text-neutro-1 fw-bold mb-3">Turn 1</p>
                <div class="d-flex gap-5">
                  <div
                    class="
                      d-flex
                      field field-calendar
                      d-flex
                      field-border
                      align-items-center
                      gap-3
                    "
                  >
                    <label
                      for="time-start-1"
                      class="fs-6 fw-bold text-neutro-1"
                    >
                      Start</label
                    >
                    <Calendar
                      id="time-start-1"
                      hourFormat="24"
                      icon="pi pi-clock"
                      :timeOnly="true"
                      :showIcon="true"
                      v-model="fields['time_start[0]']"
                    />
                  </div>
                  <div
                    class="
                      d-flex
                      field field-calendar
                      d-flex
                      field-border
                      align-items-center
                      gap-3
                    "
                  >
                    <label
                      for="time-finish-1"
                      class="fs-6 fw-bold text-neutro-1"
                      >Finish</label
                    >
                    <Calendar
                      id="time-finish-1"
                      :timeOnly="true"
                      hourFormat="24"
                      :showIcon="true"
                      icon="pi pi-clock"
                      v-model="fields['time_end[0]']"
                    />
                  </div>
                </div>
              </div>
              <div class="mr-4 mt-3" v-if="currentTime">
                <p
                  class="fs-5 fw-bold mb-3"
                  :class="[
                    currentTime && currentTime !== '1'
                      ? 'text-neutro-1'
                      : 'text-neutro-0',
                  ]"
                >
                  Turn 2
                </p>
                <div class="d-flex gap-5">
                  <div
                    class="
                      d-flex
                      field field-calendar
                      d-flex
                      field-border
                      align-items-center
                      gap-3
                    "
                  >
                    <label
                      for="time-start-1"
                      class="fs-6 fw-bold"
                      :class="[
                        currentTime && currentTime !== '1'
                          ? 'text-neutro-1'
                          : 'text-neutro-0',
                      ]"
                      >Start</label
                    >
                    <Calendar
                      id="time-start-1"
                      hourFormat="24"
                      icon="pi pi-clock"
                      :timeOnly="true"
                      :showIcon="true"
                      :disabled="currentTime === '1'"
                      v-model="fields['time_start[1]']"
                    />
                  </div>
                  <div
                    class="
                      d-flex
                      field field-calendar
                      d-flex
                      field-border
                      align-items-center
                      gap-3
                    "
                  >
                    <label
                      for="time-finish-1"
                      class="fs-6 fw-bold"
                      :class="[
                        currentTime && currentTime !== '1'
                          ? 'text-neutro-1'
                          : 'text-neutro-0',
                      ]"
                      >Finish</label
                    >
                    <Calendar
                      id="time-finish-1"
                      :timeOnly="true"
                      hourFormat="24"
                      :showIcon="true"
                      icon="pi pi-clock"
                      :disabled="currentTime === '1'"
                      v-model="fields['time_end[1]']"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <template #footer>
      <div class="d-flex justify-content-between">
        <button
          v-if="currentStep === '2' && teacherModal.status === 'add'"
          class="btn text-secondary-1 text-hover-secondary-1 py-2 px-4"
          @click="currentStep = '1'"
        >
          Back
        </button>
        <!-- <button
          v-if="teacherModal.status === 'edit-profile'"
          class="btn text-secondary-1 text-hover-secondary-1 py-2"
          style="max-width: 190px"
          @click="editTeacherJob"
        >
          Change job scheduled
        </button> -->
        <div class="d-flex gap-2 w-100 justify-content-end">
          <button
            class="
              btn
              text-secondary-1
              border-secondary-1
              bg-hover-light-secondary-1
              py-2
              px-4
            "
            @click="
              () => {
                toggleTeacherModal({ isOpen: false, status: '' });
                currentStep = '1';
              }
            "
            :disabled="isLoadingTeacherCreation"
          >
            Cancel
          </button>
          <button
            :class="getStylesBtnPrimary()"
            class="d-flex align-items-center gap-3 justify-content-center"
            v-if="teacherModal.status === 'add'"
            @click="
              () => {
                nextOrCreate();
                // currentStep = '2'
              }
            "
            :disabled="isLoadingTeacherCreation"
          >
            {{
              currentStep === "1"
                ? "Save"
                : isLoadingTeacherCreation
                ? "Creating"
                : "Create"
            }}
            <i v-if="isLoadingTeacherCreation" class="pi pi-spin pi-spinner" style="font-size: 1.5rem"></i>
          </button>

          <button
            :class="getStylesBtnPrimary()"
            class="d-flex align-items-center gap-3 justify-content-center"
            v-if="teacherModal.status === 'edit-profile'"
            @click="() => createOrUpdateTeacher('update-teacher')"
            :disabled="isLoadingTeacherUpdate"
          >
            {{ isLoadingTeacherUpdate ? "Updating" : "Update" }}
            <i v-if="isLoadingTeacherUpdate" class="pi pi-spin pi-spinner" style="font-size: 1.5rem"></i>

          </button>
          <button
            :class="getStylesBtnPrimary()"
            class="d-flex align-items-center gap-3"
            v-if="teacherModal.status === 'edit-job'"
            @click="() => createOrUpdateJob('update-job')"
            :disabled="isLoadingTeacherJobUpdate"

          >
            {{ isLoadingTeacherJobUpdate ? "Creating" : "Create" }}
            <i v-if="isLoadingTeacherJobUpdate" class="pi pi-spin pi-spinner" style="font-size: 1.5rem"></i>
          </button>
        </div>
      </div>
    </template>
  </Dialog>
</template>

<script>
import useTeacherAddOrUpdate from "@/modules/admin/composables/Members/Teachers/useTeacherAddOrUpdate";
import { useStore } from 'vuex'

export default {
  name: "TeacherModal",

  setup() {
    const getStylesBtnPrimary = () => {
      return [
        "btn",
        "text-white",
        "bg-secondary-1",
        "bg-hover-secondary-1",
        "bl-shadow",
        "py-2",
        "shadow",
        "px-4",
      ];
    };
    const store = useStore()
    return {
      ...useTeacherAddOrUpdate(),
      getStylesBtnPrimary,
      store
    };
  },
};
</script>


<style lang="scss" scoped>
.grid-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.8rem;
  &- {
    gap: 1rem;
    grid-template-columns: 10rem 1fr;
  }
}
.col-1 {
  display: grid;
  grid-template-columns: 6.5rem 1fr;
  align-items: center;
  gap: 1.5rem;
  margin-bottom: 1.4rem;
  &- {
    grid-template-columns: 5rem 1fr;
  }
}
.content-fields {
  max-height: 18rem;
}
</style>