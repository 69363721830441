<template>
  <Dialog
    header="Header"
    class="modal-custom modal-md modal-w-icon bg-white"
    :class="isLoadingClass ? 'hidden-icons' : ''"
    :modal="true"
    contentClass="px-0 mx-0"
    v-model:visible="showClassBookedModal"
    @update:visible="toggleClassBookedModal(false)"
  >
    <template #header :disabled="isLoadingClass">
      <div class="header-logo mx-auto order-1 mb-2">
        <img src="/assets/images/logo-icon-2x.png" alt="Bright Lingua" />
      </div>
      <h1 class="fs-3 fw-bold text-center text-secondary-1 w-100 mb-0">
        Your class is almost booked!
      </h1>
    </template>
    <div class="body px-4">
      <p class="text-center lh-sm mb-2">
        These classes will now show up in your <b>My classes</b> section.
      </p>
      <p class="text-primary-1 fw-bold text-center lh-sm mb-4">
        If you'd like, you can specify what you want to cover in the class
        below.
      </p>
      <div class="scheduled-classes pe-3" v-if="dates.dates.length > 0">
        <div
          class="border-primary-3 border-r-10 p-3 mb-3"
          v-for="(value, index) in infoClassModal.data"
          :key="index"
        >
          <div class="d-flex flex-column flex-md-row justify-content-between mb-3">
            <h2 class="fs-6 fw-bold">
              Teacher's {{ value.name }} {{ value.last_name }}
            </h2>
            <p>
              {{ value.formatDate }}
            </p>
          </div>
          <div class="d-flex flex-column flex-md-row align-items-md-center justify-content-between mb-3">
            <h2 class="fs-6 fw-bold white-space-nowrap">Goal's class *</h2>
            <div class="d-flex flex-wrap  gap-1">
              <Button
                type="button"
                class="
                  btn
                  border-primary-1
                  bg-primary-5
                  text-primary-1
                  bg-hover-light-primary-1
                "
                label="Bright Path"
                v-tooltip="'Take classes according to the curriculum.'"
                :class="dates.dates[index].comment_goal === 'Bright Path' ? 'btn-active-goal' : 'p-button-rounded'"

                @click="(e) => selectGoal('Bright Path', index)"
              />
              <Button
                type="button"
                class="
                  btn
                  border-accent-0
                  bg-accent-3
                  text-accent-0
                "
                :class="dates.dates[index].comment_goal === 'Conversation' ? 'btn-active-goal' : 'p-button-rounded'"
                
                label="Conversation"
                v-tooltip="'Practice speaking with native speakers.'"
                @click="(e) => selectGoal('Conversation', index)"
              />
              <Button
                type="button"
                class="
                  btn
                  border-accent-5
                  bg-accent-8
                  text-accent-5
                "
                label="Other"
                :class="dates.dates[index].comment_goal === 'Other' ? 'btn-active-goal' : 'p-button-rounded'"
                v-tooltip="'Practice any  topic of your preference.'"
                @click="(e) => selectGoal('Other', index)"
              />
            </div>
          </div>
          <div class="field-blue">
            <p class="fw-bold">Comments</p>
            <Textarea
              :autoResize="true"
              rows="3"
              v-model="dates.dates[index].comment_description"
              class="w-100 border-r-10 mt-2"
              placeholder="Give any comment you would like to share. "
            />
          </div>
        </div>
      </div>

      <!-- bg-hover-light-accent-0 -->
    </div>
    <template #footer>
      <div class="d-flex align-items-center mb-1 gap-2 text-align-left">
        <Checkbox inputId="apply" v-model="checked" :binary="true" :disabled="selectedGoals" @click="handleCheckClick = false"/>
        <label for="apply">Apply the same goal to all classes</label>
      </div>
      <button
        :disabled="isLoadingClass"
        class="
          btn
          bl-shadow
          text-white
          bg-secondary-1 bg-hover-secondary-1
          w-100
        "
        @click="() => {
          sendClass(isTeacher)
          checked = false
        }"
      >
        Submit
      </button>
    </template>
  </Dialog>
</template>

<script>
import moment from "moment";
import { ref, computed, watch, onUpdated } from "vue";
import useScheduledClass from "@/shared/composables/ScheduledClass/useScheduledClass";
import useClasses from "@/modules/students/composables/MyClasses/useClasses";
import formatDate from "@/shared/utils/changeTexts/formatDate";
import useClasse from "@/modules/students/composables/MyClasses/useClasse";
import { useRoute } from 'vue-router';
export default {
  setup() {
    const { showClassBookedModal, toggleClassBookedModal } = useScheduledClass();
    const { infoClassModal } = useClasses();
    const { dates, selectGoal, sendClass, isLoadingClass } = useClasse();

    const route = useRoute();

    const checked = ref(false)
    const handleCheckClick = ref(false)

    const selectedGoals = computed(() => {
      return !(dates.value.dates[0].comment_goal.length > 0)
    })

    watch(checked, (currentValue, oldValue) => {
      if(handleCheckClick){
        if(!currentValue){
          dates.value.dates.map(item=>item.comment_goal = '')
          return
        }
        dates.value.dates.map(item=>item.comment_goal = dates.value.dates[0].comment_goal)
      }
    });

    watch(dates.value.dates, (currentValue, oldValue) => {
      dates.value.dates.map(item => {
        if(item.comment_goal !== dates.value.dates[0].comment_goal){
          handleCheckClick.value = false
          checked.value = false
        }
      })
    });

    onUpdated(() => {
      if(infoClassModal.value.data){
        infoClassModal.value.data.map(item => {
          // console.log(moment(item.date).format("YYYY-MM-DD HH:mm:ss"))
          item.formatDate = moment(`${item.date} ${item.time_start}`).format('MMM Do, h:mm a')
        })
      }
      // `${value.date} ${value.time_start}`
    })

    const isTeacher = computed(() => {
      return route.params.isTeacher ?? null
    })

    return {
      showClassBookedModal,
      toggleClassBookedModal,
      sendClass,
      selectGoal,
      isLoadingClass,
      infoClassModal,
      formatDate,
      dates,
      checked,
      selectedGoals,
      isTeacher
    };
  },
};
</script>

<style lang="scss" scoped>
.header-logo img {
  max-width: 80px;
  width: 100%;
}
@media (max-height: 640px) {
  .header-logo img {
    width: 40px;
  }
  .header-logo + h1{
    font-size: 1.2rem !important;
  }
}

@media (min-width: 480px){
  .body{
    max-height: 200px;
  }

}
</style>
