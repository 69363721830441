<template>
  <Dialog header="Header" v-model:visible="isShowUserInfo.isOpen" :position="'topright'" :draggable="false"
    class="modal-custom modal-teacher hidden-icons bg-white">
    <template #header>
      <div class="header-user- w-100 text-center">
        <div class="position-relative text-center user-picture">
          <avatar v-if="userAuthenticated.role === 'teacher'" :image="
            teacherInfo?.photo
              ? teacherInfo?.photo
              : '/assets/images/avatar.png'
          " shape="circle" class="cover" />
          <avatar v-if="userAuthenticated.role === 'admin' || userAuthenticated.role === 'coordinator'" :image="
            adminInfo.photo ? adminInfo.photo : '/assets/images/avatar.png'
          " shape="circle" class="cover" />
          <div class="
              user-status
              w-auto
              bg-white
              py-1
              px-2
              shadow-sm
              d-flex
              gap-2
              align-items-center
            ">
            <div class="status"
              :class="status[userAuthenticated.status].class !== undefined ? status[userAuthenticated.status].class : ''">
            </div>
            <span class="fs-6">{{
                status[userAuthenticated.status].text !== undefined ? status[userAuthenticated.status].text : ''
            }}</span>
          </div>
        </div>
        <div class="mt-2">
          <template v-if="userAuthenticated.role === 'teacher'">
            <h1 class="fs-5">
              {{
                  isEmpty(teacherInfo?.name) +
                  " " +
                  isEmpty(teacherInfo?.last_name, "bar")
              }}
            </h1>
            <h2 class="fs-6 mb-0">{{ isEmpty(teacherInfo?.email) }}</h2>
            <button class="
                btn
                text-secondary-1 text-hover-secondary-1
                py-2
                px-5
                w-100
              " @click="openHideReset">
              Reset password
            </button>
          </template>
          <template v-if="userAuthenticated.role === 'student'">
            <h1 class="fs-5">
              {{
                  isEmpty(teacherInfo?.name) +
                  " " +
                  isEmpty(teacherInfo?.last_name)
              }}
            </h1>
            <h2 class="fs-6">{{ isEmpty(teacherInfo?.email) }}</h2>
          </template>
          <template v-if="userAuthenticated.role === 'admin' || userAuthenticated.role === 'coordinator'">
            <h1 class="fs-5">
              {{
                  isEmpty(adminInfo?.name) + " " + isEmpty(adminInfo?.last_name)
              }}
            </h1>
            <h2 class="fs-6">{{ isEmpty(adminInfo?.email) }}</h2>
            <button class="
                btn
                text-secondary-1 text-hover-secondary-1
                py-2
                px-5
                w-100
              " @click="openHideReset">
              Reset password
            </button>
          </template>
        </div>
        <hr />
      </div>
    </template>

    <div class="user-detail">
      <p class="fw-bold">Gender:</p>
      <p>
        {{
            userAuthenticated.role === "teacher"
              ? isEmpty(teacherInfo?.gender)
              : isEmpty(adminInfo?.gender)
        }}
      </p>
    </div>
    <div class="user-detail">
      <p class="fw-bold">Address:</p>
      <p>
        {{
            userAuthenticated.role === "teacher"
              ? isEmpty(teacherInfo?.address)
              : isEmpty(adminInfo?.address)
        }}
      </p>
    </div>
    <div class="user-detail">
      <p class="fw-bold">Phone:</p>
      <p>
        {{
            userAuthenticated.role === "teacher"
              ? isEmpty(teacherInfo?.cellphone)
              : isEmpty(adminInfo?.cellphone)
        }}
      </p>
    </div>
    <div class="user-detail">
      <p class="fw-bold">Birthday:</p>
      <p>
        {{
            userAuthenticated.role === "teacher"
              ? formatDate(isEmpty(teacherInfo?.birth), 'american')
              : formatDate(isEmpty(adminInfo?.birth), 'american')
        }}
      </p>
    </div>
    <div class="user-detail">
      <p class="fw-bold">Hire date:</p>
      <p>
        {{
            userAuthenticated.role === "teacher"
              ? formatDate(isEmpty(teacherInfo?.hire_date), 'american')
              : formatDate(isEmpty(adminInfo?.hire_date), 'american')
        }}
      </p>
    </div>
    <div class="user-detail">
      <p class="fw-bold">Role:</p>
      <div v-if="userAuthenticated.role === 'teacher'">
        <span class="bg-accent-1 py-1 px-3 text-white rounded-pill">Teacher</span>
      </div>
      <div v-if="userAuthenticated.role === 'admin'">
        <span class="bg-primary-1 py-1 px-3 text-white rounded-pill">Admin</span>
      </div>
      <div v-if="userAuthenticated.role === 'coordinator'">
        <span class="bg-primary-1 py-1 px-3 text-white rounded-pill">Coordinator</span>
      </div>
    </div>
    <hr />
    <div class="user-detail" v-if="userAuthenticated.role === 'teacher' || userAuthenticated.role === 'admin' || userAuthenticated.role === 'coordinator'">
      <p class="fw-bold">Time zone:</p>
      <p>
        {{
            userAuthenticated.role === "teacher"
              ? isEmpty(teacherInfo?.timezone)
              : isEmpty(adminInfo?.timezone)
        }}
      </p>
    </div>
    <!-- <div class="user-detail" v-if="userAuthenticated.role === 'teacher'">
      <p class="fw-bold">Work days:</p>
      <p>
        {{
            userAuthenticated.role === "teacher"
              ? isEmpty(teacherInfo?.laboral_days)
              : isEmpty(adminInfo?.laboral_days)
        }}
      </p>
    </div>
    <div class="user-detail" v-if="userAuthenticated.role === 'teacher'">
      <p class="fw-bold">Working hours:</p>
      <p>
        {{
            userAuthenticated.role === "teacher"
              ? isEmpty(teacherInfo?.working_hours)
              : isEmpty(adminInfo?.working_hours)
        }}
      </p>
    </div> -->
    <div class="teacher-video border-r-10" v-if="userAuthenticated.role === 'teacher' && teacherInfo?.link_video">
      <iframe class="w-100" :src="
        teacherInfo?.link_video
          ? getId(teacherInfo?.link_video)
          : ''
      " title="YouTube video player" frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen
        lazyload></iframe>
    </div>
  </Dialog>
</template>

<script>
import useMenuBar from "@/shared/composables/useMenuBar";
import status from "@/shared/types/typesUserStatus";
import isEmpty from "@/shared/utils/validations/isEmpty";
import useResetPassword from "@/modules/teachers/composables/useResetPassword";
import useAdminInformation from "@/modules/admin/composables/Admins/useAdminInformation";
import useTeacherInformation from "@/modules/teachers/composables/useCurrentTeacher";
import formatDate from "@/shared/utils/changeTexts/formatDate";
export default {
  name: "UserInfoModal",
  setup() {
    const { userAuthenticated, isShowUserInfo, toggleUserInfo } = useMenuBar();
    const { teacherInfo } = useTeacherInformation();
    const { adminInfo } = useAdminInformation();
    const { openHideReset } = useResetPassword();

    function getId(url) {
      const regExp =
        /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
      const match = url.match(regExp);

      return match && match[2].length === 11
        ? "https://www.youtube.com/embed/" + match[2]
        : null;
    }

    return {
      userAuthenticated,
      toggleUserInfo,
      isShowUserInfo,
      status,
      formatDate,
      teacherInfo,
      openHideReset,
      isEmpty,
      adminInfo,
      getId
    };
  },
};
</script>

<style lang="scss" scoped>
.user-detail {
  align-items: center;
  display: grid;
  gap: 1rem;
  grid-template-columns: 8rem 1fr;
  margin-bottom: 16px;
}
</style>
