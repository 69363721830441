<template>
  <header class="bg-white px-3 px-md-5 py-3">
    <div class="header d-flex align-items-center justify-content-between justify-content-md-end" >
      <div class="d-flex align-items-center position-relative" style="gap:3rem">
        <i class="pi pi-bar opacity-0"></i>
        <transition name="slide-up">
          <i v-if="!menuHamburger.isOpen" @click="toggleMenuHamburger"
            class="pi pi-bars text-primary-1 fw-bold d-block d-md-none position-absolute"></i>
          <i v-else-if="menuHamburger.isOpen" @click="toggleMenuHamburger"
            class="pi pi-times text-primary-1 fw-bold d-block d-md-none position-absolute"></i>
        </transition>
        <img class="d-block d-md-none" src="/assets/images/Logo.png" añt="Bright Lingua" />
      </div>
      <div class="header-information">
        <div class="d-flex gap-3 gap-md-4 align-items-center">
          <i class="notification-open pi pi-bell text-primary-1 pointer" style="font-size: 1.7rem" v-badge.danger="
            Object.entries(getNotificationList.data).filter(
              ([key, value]) => {
                return value.leido === 0;
              }
            ).length === 0 ? '0' : Object.entries(getNotificationList.data).filter(
              ([key, value]) => {
                return value.leido === 0;
              }
            ).length
          " @click="() => toggleNotificationList(!getNotificationList.isOpen)"></i>
          <div class="
              d-flex
              header-user
              align-items-center
              gap-3
              px-1
              px-md-4
              rounded-pill
              pointer
            " :class="{
              'bg-secondary-3': isCurrentStudentProfile,
            }" @click="toggleUserInfo">
            <div class="user-names d-none d-md-flex flex-column text-end"
              v-if="!adminInfo.isLoading && !studentInfo.isLoading || teacherInfo !== null">
              <span class="text-neutro-1 user-name fs-6">{{
                  userAuthenticated.role === "teacher"
                    ? isEmpty(teacherInfo?.name, "bar") +
                    " " +
                    isEmpty(teacherInfo?.last_name, "bar")
                    : userAuthenticated.role === "admin" || userAuthenticated.role === 'coordinator'
                      ? isEmpty(adminInfo?.name, "bar") +
                      " " +
                      isEmpty(adminInfo?.last_name, "bar")
                      : isEmpty(studentInfo?.name, "bar") +
                      " " +
                      isEmpty(studentInfo?.last_name, "bar")
              }}</span>
              <span class="text-secondary-1 text-uppercase user-rol" v-if="userAuthenticated.role !== 'student'">
                {{ userAuthenticated.role }}
              </span>
            </div>

            <div class="user-names d-flex flex-column text-end" v-else>
              <skeleton borderRadius="10px" height="1rem" width="10rem" class="mb-2 skeleton-light" />
              <skeleton borderRadius="10px" v-if="userAuthenticated.role !== 'student'" height="1rem" width="6rem"
                class="skeleton-light" />
            </div>
            <div class="user-photo d-flex align-items-center"
              v-if="!adminInfo.isLoading && !studentInfo.isLoading || teacherInfo !== null">
              <avatar v-if="userAuthenticated.role === 'teacher'" :image="
                teacherInfo?.photo
                  ? teacherInfo?.photo
                  : '/assets/images/avatar.png'
              " shape="circle" size="large" class="pointer cover" />
              <avatar v-if="userAuthenticated.role === 'student'" :image="
                studentInfo.photo
                  ? studentInfo.photo
                  : '/assets/images/avatar.png'
              " shape="circle" size="large" class="pointer cover" />
              <avatar v-if="userAuthenticated.role === 'admin' || userAuthenticated.role === 'coordinator'" :image="
                adminInfo.photo
                  ? adminInfo.photo
                  : '/assets/images/avatar.png'
              " shape="circle" size="large" class="pointer cover" />

              <div class="user-status" :class="status[userAuthenticated.status]?.class"></div>
            </div>

            <div class="user-photo d-flex align-items-center" v-else>
              <skeleton shape="circle" width="3.2rem" height="3.2rem" class="skeleton-light" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import useMenuBar from "@/shared/composables/useMenuBar";
import usePrivateLayout from "@/shared/composables/Layouts/usePrivateLayout";
import useSharedUI from "@/shared/composables/useSharedUI";
import isEmpty from "@/shared/utils/validations/isEmpty";

import useStudentInformation from "@/modules/students/composables/StudentProfile/usePersonalDetails";
import useAdminInformation from "@/modules/admin/composables/Admins/useAdminInformation";
import useTeacherInformation from "@/modules/teachers/composables/useCurrentTeacher";

export default {
  name: "MenuBarComponent",
  setup() {
    const { isTeacher, getNotificationList } = useSharedUI();
    const { isCurrentStudentProfile, status } = usePrivateLayout();
    const { studentInfo } = useStudentInformation();
    const { teacherInfo } = useTeacherInformation();
    const { adminInfo } = useAdminInformation();
    return {
      ...useMenuBar(),
      getNotificationList,
      isCurrentStudentProfile,
      isTeacher,
      status,
      teacherInfo,
      studentInfo,
      isEmpty,
      adminInfo,
    };
  },
};
</script>
