import { useStore } from "vuex";
import { computed } from "vue";

const useAdminInformation = () => {
  const store = useStore();
  let adminInfo = computed(() => store.getters["admins/adminInfo"]);

  return {
    adminInfo,
  };
};

export default useAdminInformation;
