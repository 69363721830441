import { ref, watchEffect, onMounted, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import useCalendar from "@/shared/composables/Calendar/useCalendar";
import useSharedUI from "../../../../shared/composables/useSharedUI";
const useClasse = () => {
  const isLoadingClass = ref(false);
  const store = useStore();
  const router = useRouter();
  const dates = ref({
    dates: [],
  });
  const { sendNotificationClassscheduled } = useSharedUI();

  const { handleClientLoad, createEvent } = useCalendar();
  onMounted(() => {
    handleClientLoad();
  });

  const selectGoal = (goal, idDate) => {
    dates.value.dates[idDate] = {
      ...dates.value.dates[idDate],
      comment_goal: goal,
    };
  };
  const sendClass = async (type) => {
    isLoadingClass.value = true;
    let new_dates = dates.value;
    let tokens_firebase = [];
    new_dates.dates.forEach((date) => {
      delete date.id;
      delete date.scheduled_id;
      delete date.photo;
      tokens_firebase.push(date.token_firebase);
      delete date.token_firebase;
      delete date.name;
      delete date.last_name;
      delete date?.timezone;
      delete date?.url_video;
    });

    if (type !== null) {
      new_dates.type = 1;
    }

    const dateTeacher = [
      ...new Set(
        new_dates.dates.map((item) => item.date + " " + item.teacher_id)
      ),
    ];

    const groupsByDate = dateTeacher.map((date) => {
      const group = new_dates.dates.filter(
        (item) => date == item.date + " " + item.teacher_id
      );
      group.sort((a, b) => {
        return (
          new Date(a.date.replace(/-/g, "/") + " " + a.time_start) -
          new Date(b.date.replace(/-/g, "/") + " " + b.time_start)
        );
      });
      return group;
    });

    const groupsByTime = groupsByDate.map((group) => {
      const data = [];
      let current = 0;

      if (group.length == 1) data[current] = group;
      group.map((item, i) => {
        item.time_end_aux = item.time_end;

        if (group[i + 1]) {
          const time = item.time_start.split(":");
          const time2 = group[i + 1].time_start.split(":");
          if (
            (parseInt(time[0]) == parseInt(time2[0]) &&
              parseInt(time2[1]) == 30) ||
            (parseInt(time2[0]) - parseInt(time[0]) == 1 &&
              Math.abs(parseInt(time2[1]) - parseInt(time[1])) == 30)
          ) {
            if (!data[current]?.length) data[current] = [];
            if (
              data[current].some(
                (val) =>
                  val.time_start + val.time_end + val.teacher_id ==
                  item.time_start + item.time_end + item.teacher_id
              )
            ) {
              data[current].push(group[i + 1]);
            } else if (
              data[current].some(
                (val) =>
                  val.time_start + val.time_end + val.teacher_id ==
                  group[i + 1].time_start +
                    group[i + 1].time_end +
                    group[i + 1].teacher_id
              )
            ) {
              data[current].push(item);
            } else {
              data[current].push(item, group[i + 1]);
            }
          } else {
            current++;
          }
        }
      });

      const procesed = data
        .map((item) => {
          item?.map(
            (date) => (date.time_end_aux = item[item.length - 1].time_end)
          );
          return item;
        })
        .flat();

      return procesed;
    });

    let { res } = await store.dispatch("students/createClassByTime", {
      payload: { dates: groupsByTime.flat() },
    });

    if (!res) {
      // store.commit("shared/setClassBookedModal", { isOpen: false });
    } else {
      await store.dispatch("students/dashboardStart");
      await store.dispatch("students/classesTakenThisDay");
      const notification = { isOpen: true };
      notification.status = "success";
      notification.message = `We told your teacher what topics you want to cover in each class.`;
      store.commit("shared/uiNotificationStatus", notification);
      store.dispatch("shared/resetNotificationStatus");
      router.push({ name: "student-my-classes" });
      const studentInformation = store.getters["students/studentInformation"];
      new_dates.dates.forEach((date, index) => {
        sendNotificationClassscheduled(
          date,
          tokens_firebase[index],
          studentInformation.name + " " + studentInformation.last_name
        );
      });
      new_dates.dates.forEach((date) => {
        createEvent({
          summary: "My class",
          location: "",
          visibility: "public",
          description: "",
          start: {
            dateTime: `${date.date}T${date.time_start}-05:00`,
            timeZone: "America/Lima",
          },
          end: {
            dateTime: `${date.date}T${date.time_end}-05:00`,
            timeZone: "America/Lima",
          },
          recurrence: ["RRULE:FREQ=DAILY;COUNT=1"],
          attendees: [],
          reminders: {
            useDefault: false,
            overrides: [
              { method: "email", minutes: 24 * 60 },
              { method: "popup", minutes: 10 },
            ],
          },
        });
      });
      store.commit("shared/setClassBookedModal", { isOpen: false });
      store.commit("students/scheduledClass", { data: [] });
      store.commit("students/infoClassModal", { data: [] });
    }
    isLoadingClass.value = false;
  };

  watchEffect(() => {
    let infoClassModal = store.getters["students/infoClassModal"];
    if (infoClassModal.data.length > 0) {
      dates.value.dates = [];
      infoClassModal.data.forEach((item) => {
        dates.value.dates.push({
          ...item,
          comment_description: "",
          comment_goal: "",
        });
      });
    }
  });

  return {
    dates,
    selectGoal,
    isLoadingClass,
    sendClass,
  };
};

export default useClasse;
