<template>
  <Dialog header="Header" class="modal-custom modal-medium btns-max hidden-icons header-blue bg-white"
    v-model:visible="exportChartModal.isOpen" :modal="true" :draggable="false"
    contentClass="px-0 mx-0  overflow-hidden">
    <template #header>
      <h1 class="bg-primary-1 fs-5 text-start text-white w-100 mb-0">
        Export Charts
      </h1>
    </template>
    <div class="body px-4 pt-3 mt-2">
      <p class="fs-6 text-neutro-1 fw-bold mb-2">
        1. Select the date range you want to filter for your chart
      </p>
      <div class="
          d-flex
          align-items-center
          gap-2
          justify-content-between
          w-100
          mb-3
        ">
        <div class="field-outline-blue d-flex gap-2 align-items-center">
          <p>Start:</p>
          <Calendar id="icon" v-model="filterReport.date_start" class="w-100 calendar-small" name="date_start"
            dateFormat="mm/dd/yy" />
        </div>
        <div class="field-outline-blue d-flex gap-2 align-items-center">
          <p>Finish:</p>
          <Calendar id="icon" v-model="filterReport.date_end" class="w-100 calendar-small" name="date_end"
            dateFormat="mm/dd/yy" />
        </div>
      </div>
      <p class="fs-6 text-neutro-1 fw-bold mb-2">
        2. Select the charts you want to export
      </p>
      <div class="
          form-check
          d-flex
          gap-2
          align-items-center
          justify-content-start
          w-100
          mb-2
        " v-for="(chart, index) in optionsCharts" :key="index">
        <RadioButton class="" type="checkbox" name="reportFilter"  v-model="optionsFilterReport" :value="chart.label" :id="{ index }" />
        <label class="form-check-label" :for="index">
          {{ chart.label }}
        </label>
      </div>
    </div>
    <template #footer>
      <div class="d-flex gap-2 justify-content-end">
        <button @click="toggleModalReport" class="
            btn
            text-secondary-1
            border-secondary-1
            bg-hover-light-secondary-1
            py-2
            px-4
          " :disabled="isLoadingFilterReport">
          Cancel
        </button>
        <button :disabled="isLoadingFilterReport" @click="reportFilterReport" class="
            btn
            text-white
            bg-secondary-1 bg-hover-secondaty-1
            bl-shadow
            py-2
            shadow
            px-4
          ">
          Export
        </button>
      </div>
    </template>
  </Dialog>
</template>

<script>
import useFinancial from "@/modules/admin/composables/Analitycs/Financial/useFinancial";
export default {
  name: "ExportChartsModalAnalityc",
  setup() {
    const { exportChartModal, optionsCharts, toggleModalReport, filterReport, reportFilterReport, isLoadingFilterReport , optionsFilterReport } = useFinancial();
    return { exportChartModal, optionsCharts, toggleModalReport, filterReport, reportFilterReport, isLoadingFilterReport , optionsFilterReport };
  },
};
</script>

<style>
</style>