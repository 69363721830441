<template>
  <Dialog
    header="Header"
    v-model:visible="teachersChangeRole.isOpen"
    :modal="true"
    class="modal-custom hidden-icons btns-max modal-small"
  >
    <template #header>
      <h1 class="fs-2 fw-bold text-center text-secondary-1 w-100">
        Change role
      </h1>
    </template>
    <div class="content" v-on:submit.prevent="">
      <div class="row- mb-2 field-blue">
        <p class="fw-bold">Current Role:</p>

        <input-text
          type="text"
          readonly
          :value="teacher.data.role"
          class="input-small rounded-0"
        />
      </div>
      <div class="row- mb-2 field-blue">
        <p class="fw-bold">Change to:</p>
        <dropdown
          :options="listRoles"
          v-model="payload.id"
          optionLabel="value"
          optionValue="id"
          placeholder="Select role"
          class="dropdown-small p-0 rounded-0 w-100"
        />
      </div>
    </div>
    <template #footer>
      <div class="d-flex justify-content-end gap-2">
        <button
          :disabled="isUpdating"
          @click="() => toggleTeacherChangeRole(false)"
          class="btn text-secondary-1 border-secondary-1 bg-hover-light-secondary-1"
        >
          Cancel
        </button>
        <button
          @click="updateRole"
          :disabled="isUpdating"
          class="btn text-white bg-secondary-1 bg-hover-secondary-1 py-2 bl-shadow"
        >
          Save
        </button>
      </div>
    </template>
  </Dialog>
</template>

<script>
import useTeacher from "@/modules/admin/composables/Members/Teachers/useTeacher";
export default {
  props: {
    roles: {
      type: Array,
      default: [],
    },
  },
  setup() {
    const {
      toggleTeacherChangeRole,
      teachersChangeRole,
      listRoles,
      teacher,
      isUpdating,
      updateRole,
      payload
    } = useTeacher();
    return {
      toggleTeacherChangeRole,
      isUpdating,
      listRoles,
      payload,
      updateRole,
      teacher,
      teachersChangeRole,
    };
  },
};
</script>
