<template>
  <Dialog
    header="Header"
    v-model:visible="getNotificationList.isOpen"
    :position="'topright'"
    :modal="false"
    :dismissableMask="true"
    contentClass="p-0 overflow-hidden"
    class="modal-custom notification-list-modal hidden-header hidden-icons"
  >
    <!-- <template v-if="Object.entries(getNotificationList.data).filter(([key,value]) => { return value.leido === 0 } ).length > 0 || Object.entries(getNotificationList.data).filter(([key,value]) => { return value.show === 0 } ).length > 0"> -->
    <template v-if="orderNotifications.length > 0">
      <div class="notification-list scroll">
        <template
          v-for="(item, index) in orderNotifications.flat(Infinity)
          .filter(item => item && !item.message.includes('undefined'))"
          :key="index"
        >
          <div
          v-if="item"
            @click="() => setShowNotificaciones(item)"
            class="notification p-3 pointer"
            :class="item?.show === 0 ? 'activated' : ''"
          >
            <p
              class="
                notification-date
                text-primary-1
                mb-1
                fw-bold
                lh-sm
                text-uppercase
              "
            >
              {{ moment(item.date).format('MMMM DD YYYY') }}

              |
              {{ formatDistance(new Date(item.date), new Date(currentTime), 
              { 
                includeSeconds: true,
                addSuffix: true
               }) }}

            </p>
            <p class="notification-title fw-bold mb-1 lh-sm first-letter-uppercase">
              <span class="first-letter-uppercase">
                {{ item.title }}
              </span>
            </p>
            <p class="notification-description lh-sm">
              {{ item.message }}
            </p>
          </div>
        </template>
      </div>
    </template>
    <template v-else>
      <div class="notification-empty text-center p-3 bg-accent-3 rounded">
        <h1 class="text-primary-1 fs-5 mb-0 fw-bold">No Notifications</h1>
      </div>
    </template>
  </Dialog>
</template>

<script>
// import moment from "moment";
import moment from "moment-timezone";
import useSharedUI from "@/shared/composables/useSharedUI";
import formatDate from "@/shared/utils/changeTexts/formatDate";
import { watchEffect, ref, computed, onUpdated, watch } from "vue";
import { useStore } from "vuex";
import { formatDistance } from "date-fns";
export default {
  name: "NotificationListModal",
  setup() {
    const store = useStore();
    const { getNotificationList, setShowNotificaciones } = useSharedUI();
    const orderNotifications = ref([]);
    const feedbackNots = ref([]);
    const refer = ref([]);
    const timezone = computed(() =>{
      return store.getters['shared/getTimezone']
    })
    const currentTime = computed(() =>{
      return store.getters['shared/currentTimeTimezone']
    })

    const notificationList = computed(() =>getNotificationList.value.data)

    const getEntries = (obj) => {
      return Object.values(obj).map(item => {
        if(item?.date && item?.message && item?.key) {
          return item
        }
        if(typeof item == 'object'){
          return getEntries(item)
        }else{
          return item
        }
      })
    }
    watch(notificationList, (newval) => {
      if (Object.entries(getEntries(notificationList.value).flat(Infinity).filter(item => typeof item == 'object')).length >= 0) {
        let data = JSON.parse ( JSON.stringify(getEntries(notificationList.value).flat(Infinity).filter(item => typeof item == 'object')))
        orderNotifications.value = [];
        Object.entries(data).forEach(
          ([key, value, index]) => {
            value.date = moment.tz(value.date, (value.timezone ?? 'America/Detroit'))
            value.date.tz(timezone.value ?? 'America/Detroit').format();
            value.date = value.date.format("YYYY/MM/DD HH:mm:ss")
            if(value.route === "teacher-students-feedback" ||
            value.route === "student-teachers-feedback"
            ){
              feedbackNots.value.push(value)
              return 
            }else if(value.type === "refer"){
              refer.value.push(value)
              return 
            }
            orderNotifications.value.push(value);
          }
        );
          feedbackNots.value.sort((a, b) => {
            return new Date(b.date.replace(/-/g, "/")) - new Date(a.date.replace(/-/g, "/"));
          });
          orderNotifications.value.push(feedbackNots.value[0])

          refer.value.sort((a, b) => {
            return new Date(b.date.replace(/-/g, "/")) - new Date(a.date.replace(/-/g, "/"));
          });
          orderNotifications.value.push(refer.value[0])

        orderNotifications.value.sort((a, b) => {
          return new Date(b.date.replace(/-/g, "/")) - new Date(a.date.replace(/-/g, "/"));
        })
      }
    });

    return {
      getNotificationList,
      orderNotifications,
      setShowNotificaciones,
      formatDate,
      moment,
      store,
      timezone,
      currentTime,
      formatDistance
    };
  },
};
</script>
