<template>
  <Dialog header="Header" v-model:visible="confirmCancelClass.isOpen" :modal="true"
    class="modal-custom hidden-content hidden-icons">
    <template #header>
      <div class="header d-flex gap-3">
        <div class="header-logo">
          <img src="/assets/images/logo-icon.png" alt="Bright Lingua" />
        </div>
        <div class="header-texts">
          <h1 class="title text-secondary-1 fs-6 mb-0">WAIT!</h1>
          <h2 class="question text-primary-0 fw-bolder fs-4">
            Are you sure to cancel the class?
          </h2>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="d-flex flex-row justify-content-end align-items-end gap-2">
        <button :disabled="confirmCancelClass.isLoading" class="
          btn
          text-secondary-1
          border-secondary-1
          bg-hover-light-secondary-1
          p-2
          px-5
        " @click="() => {
          cancelClass(confirmCancelClass.id, confirmCancelClass.classe);
        
        }">
          Yes
        </button>
        <button :disabled="confirmCancelClass.isLoading" class="
          btn
          text-white
          bg-secondary-1 bg-hover-secondary-1
          p-2
          px-5
          bl-shadow
        " @click="() => toggleCancelClass(false)">
          No
        </button>
      </div>

    </template>
  </Dialog>
</template>

<script>
import useClasses from '../../composables/MyClasses/useClasses';
import { useStore } from 'vuex';
export default {
  name: "ConfirmCancelClass",
  setup() {
    const store = useStore()
    const { confirmCancelClass, toggleCancelClass, cancelClass } =
      useClasses();
    return {
      confirmCancelClass,
      toggleCancelClass,
      cancelClass,
      store
    };
  },
};
</script>

