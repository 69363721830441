import { ref, computed, watchEffect } from "vue";
import { useStore } from "vuex";
import hasAStringALowercase from "@/shared/utils/validations/hasAStringALowercase";
import hasAStringAUppercase from "@/shared/utils/validations/hasAStringAUppercase";
import isEqual from "@/shared/utils/validations/isEqual";

const useResetPassword = () => {
  const store = useStore();
  const fields = ref({ password: "", password_confirmation: "" });
  let requirements = ref([
    {
      text: "Must contain at least 10 characters",
      isValidate: false,
    },
    {
      text: "Must contain at least 1 lowercase",
      isValidate: false,
    },
    {
      text: "Must contain at least 1 capital letter",
      isValidate: false,
    },
    {
      text: "The passwords must be equal",
      isValidate: false,
    },
  ]);
  const requirementsValidated = computed(
    () => requirements.value.filter((req) => req.isValidate).length
  );
  const isCreatePassLoading = ref(false);

  watchEffect(() => {
    const { password, password_confirmation } = fields.value;
    if (password.length >= 10) requirements.value[0].isValidate = true;
    else requirements.value[0].isValidate = false;

    requirements.value[1].isValidate = hasAStringALowercase(password);
    requirements.value[2].isValidate = hasAStringAUppercase(password);
    requirements.value[3].isValidate = isEqual(password, password_confirmation);
  });
  const openHideReset = () => {
    const { isOpen } = store.getters["teachers/toggleModalResetPassword"];
    store.commit("teachers/toggleModalResetPassword", { isOpen: !isOpen });
  };

  const toggleModalResetPassword = computed(() => {
    return store.getters["teachers/toggleModalResetPassword"];
  });

  const createPassword  = async () => {
    isCreatePassLoading.value = true;
    let  { success } =  await store.dispatch("teachers/resetPassword", {payload:fields.value});
    if(success){
      openHideReset(false)
      let user = JSON.parse(localStorage.getItem("bl-user"));
      user.has_random_password = true;
      localStorage.setItem("bl-user", JSON.stringify(user));
      store.commit("shared/userAuthentication",{has_random_password:true})
    }
    isCreatePassLoading.value = false;

    fields.value = { password: "", password_confirmation: "" }

  }
  return {
    toggleModalResetPassword,
    openHideReset,
    createPassword,
    isCreatePassLoading,
    fields,
    requirements,
    requirementsValidated,
  };
};

export default useResetPassword;
